import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { ClientModel } from 'src/app/models/client.model';
import { PermitSetupModel } from 'src/app/models/permit-setup.model';
import { DeviceModel } from 'src/app/models/device.model';
import { AppearanceService } from '../appearance/appearance.service';
import { TerminalConfig } from 'src/app/models/terminal-config.model';
import { PrinterConfigModel } from 'src/app/models/printer-config.model';
import { Constants } from 'src/app/constants/constants';


@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  private settingsKey: string = Constants.kStorage;

  private defaults = {
    "storage": 'KioskApp'
  };

  settings: any;


  constructor(private storage: Storage, private appearanceService: AppearanceService) 
  { 
    storage.create().then(() => {
      // Storage is ready to use
      // Note: ready() is only available in 1.1.7 or greater!
      this.load();
    });
  }


  // Setters & Getters


  saveClient(client)
  {
    this.setValue(Constants.kClient, client);
  }

  getClient(): ClientModel {
    try {
        var client = this.getValue(Constants.kClient);
        return client;
    } catch(e) {
        return null;
    }
  }


  savePermitSetup(permit)
  {
    this.setValue(Constants.kPermit, permit);
  }

  getPermitSetup(): PermitSetupModel {
    try {
        return this.getValue(Constants.kPermit);
    } catch(e) {
        return null;
    }
  }


  savePrinterConfig(config)
  {
    this.setValue(Constants.kPrinterConfig, config);
  }

  getPrinterConfig(): PrinterConfigModel {
    try {
        return this.getValue(Constants.kPrinterConfig);
    } catch(e) {
        return new PrinterConfigModel();
    }
  }


  saveCanAccessSetupPage(enabled)
  {
    this.setValue(Constants.kSetupAccess, enabled);
  }

  getCanAccessSetupPage(): boolean {
    try {
        return this.getValue(Constants.kSetupAccess);
    } catch(e) {
        return false;
    }
  }


  saveDevice(device) {

    this.setValue(Constants.kCurDev, device);
  }

  getDevice(): DeviceModel {

    try {
        return this.getValue(Constants.kCurDev);
    } catch(e) {
        return null;
    }
  }


  saveDeviceUuid(uuid) {

    this.setValue(Constants.kCurDevUuid, uuid);
  }

  getDeviceUuid(): string {

    try {
        return this.getValue(Constants.kCurDevUuid);
    } catch(e) {
        return null;
    }
  }



  saveTerminalConfig(array) {

    this.setValue(Constants.kTerminalConfig, array);
  }

  getTerminalConfig(): TerminalConfig {

    try {
        return this.getValue(Constants.kTerminalConfig);
    } catch(e) {

        return null;
    }
  }



  setLightThemeEnabled(toggle)
  {
    this.setValue(Constants.kAppTheme, toggle);
  }

  getLightThemeEnabled(): boolean {
    try {
        return this.getValue(Constants.kAppTheme);
    } catch(e) {
        return true;
    }
  }



  // Storage


  clearAll()
  {
    this.storage.clear();
  }


  load() {

    if (Constants.CLEAR_STORAGE)
    {
      this.storage.clear();
    }

    this.storage.get(this.settingsKey).then((value) => {
        if (value) {

            this.settings = value;
        } else {
            this.setDefaults(this.defaults).then((val) => {
                this.settings = val;
            })
        }

        this.appearanceService.triggerEvent('storage:loaded', {
          time: new Date()
        });

        this.appearanceService.setTheme(this.getLightThemeEnabled());
      });
  }



  saveApiEndpointMode(url) {

    this.setValue(Constants.kEnvMode, url);
  }

  getApiEndpointMode(): number {

    try {
        return this.getValue(Constants.kEnvMode);
    } catch(e) {
        return Constants.API_PROD;
    }
  }


  setDefaults(value: any) {
    return this.storage.set(this.settingsKey, value);
  }


  setValue(key: string, value: any) {

    if (this.settings)
    {
      this.settings[key] = value;
      this.storage.set(this.settingsKey, this.settings);
    }
  }

  getValue(key: string) {

      return this.settings[key];
  }
}
