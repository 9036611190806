import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse
 } from '@angular/common/http';
 import { Observable, throwError } from 'rxjs';
 import { retry, catchError } from 'rxjs/operators';
import { Injectable } from "@angular/core";

 @Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        retry(1),

        catchError((error: HttpErrorResponse) => {

          if (!window.navigator.onLine) {

            return throwError(() => 'Check your internet connection.');
          }

          if(error.error)
          {
            if(error.error.Message)
            {
              return throwError(() => error.error.Message);
            }
          }

          return throwError(() => error.message);
        })
      )
  }

 }
