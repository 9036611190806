import { Injectable } from '@angular/core';
import { BaseService } from '../base/base.service';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiService extends BaseService {

  constructor(http: HttpClient) { 

    super(http);
  }

  public baseUrl: string;


  public login(data) 
  {
    return this.saveData("/kiosk-app/login", data).pipe(map((res:any) => {
      this.id = res.SessionId
      return res
    }));
  }

  public syncClient(payload) {
    return this.saveData("/kiosk-app/sync", payload);
  }

  public logout(deviceUid) 
  {
    const payload = { "DeviceUid": deviceUid };
    return this.saveData("/kiosk-app/user/logout", payload);
  }

  public createCheckoutRequest(data) {
    return this.saveData("/stripe/checkout/requested", data);
  }

  public checkPaymentIntentStatusIssue(data) {
    return this.saveData("stripe/checkout/verify", data);
  }

  public checkoutSuccess(data) {
    return this.saveData("/stripe/checkout/completed", data);
  }

  public doubleCheckTheyDontAlreadyHaveParkingPermit(data) {
    return this.saveData("/driver/app/check/permit", data);
  }

  public checkForNoReturnMinutesAndAnprEntranceAndActivePermit(data) {
    return this.saveData("/kiosk/pre/check", data);
  }

  public getChargeForMinutes(data) {
    return this.saveData("/permit/find/charge", data);
  }

  public makePurchaseOnTerminal(data) {
    return this.saveDataTerminal("/unknown", data);
  }

  public pingApi(data) {
    return this.saveData("/kiosk/ping", data);
  }

  public checkSecretKeyAndConfig(payload) {
    return this.saveData("/kiosk/config/check", payload);
  }

  public saveKioskSetup(data) {
    return this.saveData("/kiosk/config/update", data);
  }

  public getCardReaderToken(data) {
    return this.saveData("/stripe/terminal/token", data);
  }

  public terminalCapturePaymentIntent(data) {
    return this.saveData("/stripe/terminal/capture", data);
  }

  public registerCardReader(data) {
    return this.saveData("/stripe/terminal/register", data);
  }

  public terminalStartCheckout(data) {
    return this.saveData("/stripe/terminal/checkout", data);
  }

  public terminalCompleteCheckout(data) {
    return this.saveData("/stripe/terminal/finalise", data);
  }


  public getUnlockedReward(data) {
    return this.saveData("/kiosk/reward/check", data);
  }

  public freeCheckout(data)
  {
    return this.saveData("/kiosk/free/checkout", data);
  }
}
