import { Injectable } from '@angular/core';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { DeviceModel } from 'src/app/models/device.model';
import { AppearanceService } from '../appearance/appearance.service';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { DeviceDetectorService } from 'ngx-device-detector';
import { v4 as uuidv4 } from 'uuid';


@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  currentDevice: DeviceModel;


  constructor(private localStorageService: LocalStorageService, private device: Device, 
    private deviceDtctService: DeviceDetectorService, private appearanceService: AppearanceService) 
  { 
    this.appearanceService.listenForEvent('storage:loaded', (data: any) => {

      this.loadFromLocalData();

    });
  }


  loadFromLocalData() 
  {
    this.currentDevice = this.localStorageService.getDevice();
   
    this.getDeviceUuid();

    if (this.appearanceService.isMobileDevice())
    {
      this.setMobileDetails();
    }
    else
    {
      this.setBrowserDetails();
    }
    

    this.localStorageService.saveDevice(this.currentDevice);
  }


  setBrowserDetails()
  {
    var browserInfo = this.deviceDtctService.getDeviceInfo();

    if (browserInfo.device == "Unknown")
    {
      this.currentDevice.deviceModel = browserInfo.browser;
    }
    else
    {
      this.currentDevice.deviceModel = browserInfo.device;
    }
    
    this.currentDevice.deviceType = browserInfo.os;
    this.currentDevice.deviceOs = browserInfo.os_version;
  }


  setMobileDetails()
  {
    this.currentDevice.deviceModel = this.device.model;
    this.currentDevice.deviceType = this.device.platform;
    this.currentDevice.deviceOs = this.device.version;
  }


  public logout() 
  {

  }


  // Getters -------------------------------------------------------------------------


  public getDeviceUuid(): string
  {
    if (this.currentDevice)
    {
      if (this.currentDevice.deviceUuid)
      {
        return this.currentDevice.deviceUuid;
      }
    }

    var savedUuid = this.localStorageService.getDeviceUuid();

    var uuidString = null;

    if (savedUuid)
    {
      uuidString = savedUuid;
    }
    else
    {
      if (this.appearanceService.isMobileDevice())
      {
        uuidString = this.device.uuid;
      }
      else
      {
        uuidString = uuidv4();
      }

      this.localStorageService.saveDeviceUuid(uuidString);
    }

    if (!this.currentDevice)
    {
      this.currentDevice = new DeviceModel();
    }

    if (uuidString)
    {
      if (this.currentDevice.deviceUuid != uuidString)
      {
        this.currentDevice.deviceUuid = uuidString;
      }
    }

    return this.currentDevice.deviceUuid;
  }


  public updateDeviceUid(deviceUid)
  {
    this.currentDevice.deviceUid = deviceUid;
    this.localStorageService.saveDevice(this.currentDevice);
  }


  public getDeviceUid(): number
  {
    if (this.currentDevice)
    {
      if (this.currentDevice.deviceUid)
      {
        return this.currentDevice.deviceUid;
      }
    }

    return null;
  }

  
  public getDeviceModel(): string
  {
    if (this.currentDevice)
    {
      if (this.currentDevice.deviceModel)
      {
        return this.currentDevice.deviceModel;
      }
    }

    return null
  }

  public getDeviceType(): string
  {
    if (this.currentDevice)
    {
      if (this.currentDevice.deviceType)
      {
        return this.currentDevice.deviceType;
      }
    }

    return null
  }

  public getDeviceOs(): string
  {
    if (this.currentDevice)
    {
      if (this.currentDevice.deviceOs)
      {
        return this.currentDevice.deviceOs;
      }
    }

    return null
  }

  public isDeviceLoggedIn(): boolean
  {
    if (this.currentDevice)
    {
      if (this.currentDevice.deviceUid)
      {
        return true;
      }
    }

    return false;
  }
}
