import { Injectable } from '@angular/core';
import { LoadingController, ToastController, Platform } from '@ionic/angular';
import { Subject, Subscription } from 'rxjs';
import { Constants } from 'src/app/constants/constants';

@Injectable({
  providedIn: 'root'
})
export class AppearanceService {

  private eventsObservable: { [key: string]: Subject<any>; } = {};
  loading: any;
  loadingVisible: boolean = false;
  isCordova: boolean = false;
  private lightsOn: boolean = false;


  constructor(private loadingCtrl: LoadingController, private toastController: ToastController,
    private platform: Platform) {

      if (this.platform.is("cordova"))
      {
        this.isCordova = true;
      }
  }

  
  listenForEvent(topic: string, observer: (_: any) => void): Subscription
  {
    if (!this.eventsObservable[topic]) 
    {
      // You can also use ReplaySubject with one concequence
      this.eventsObservable[topic] = new Subject<any>();
    }

    return this.eventsObservable[topic].subscribe(observer);
  }


  triggerEvent(topic: string, data?: any): void 
  {
    const subject = this.eventsObservable[topic];

    if (!subject) 
    {
      // Or you can create a new subject for future subscribers
      return;
    }

    subject.next(data);
}


  stopListeningForEvent(topic: string): null 
  {
    const subject = this.eventsObservable[topic];

    if (!subject) 
    {
        return;
    }

    subject.complete();
    delete this.eventsObservable[topic];
  }


  async presentToast(message, toastType) 
  {
    var cssClass = "";

    switch(toastType)
    {
      case Constants.TOAST_SUCCESS:
        cssClass = "toast-success";
        break;

      case Constants.TOAST_ERROR:
        cssClass = "toast-error";
        break;

      case Constants.TOAST_INFO:
        cssClass = "toast-info";
        break;
    }

    const toast = await this.toastController.create({
      message: message,
      cssClass: cssClass,
      position: 'top',
      duration: 3000
    });

    toast.present();
  }


  public async showLoading(message: string): Promise<void> 
  {
    if (!this.loadingVisible) 
    {
        this.loading = await this.loadingCtrl.create({
          message: message
        });

        this.loadingVisible = true;
        return await this.loading.present();
    } 
    else 
    {
        // If loader is showing, only change text, won't create a new loader.
        this.loadingVisible = true;
        this.loading.message = message;
    }
  }


  public async dismissLoading(): Promise<void> 
  {
      if (this.loading && this.loadingVisible) 
      {
          this.loadingVisible = false;
          await this.loading.dismiss();
      }
  }


  public isMobileDevice(): boolean
  {
    return this.isCordova;
  }


  public getPaymentPlatform()
  {
    if (this.platform.is("cordova"))
    {
      if (this.platform.is("ios"))
      {
        return Constants.PAYMENT_SOURCE_PLATFORM_IOS;
      }
      else if (this.platform.is("android"))
      {
        return Constants.PAYMENT_SOURCE_PLATFORM_ANDROID;
      }
    }

    return Constants.PAYMENT_SOURCE_PLATFORM_WEB;
  }


  setTheme(lightsOn)
  {
    this.lightsOn = lightsOn;

    if (!this.lightsOn)
    {
      document.body.setAttribute('data-theme', 'dark');
    }
    else
    {
      document.body.setAttribute('data-theme', 'light');
    }
  }


  toggleTheme()
  {
    this.lightsOn = !this.lightsOn;

    if (!this.lightsOn)
    {
      document.body.setAttribute('data-theme', 'dark');
    }
    else
    {
      document.body.setAttribute('data-theme', 'light');
    }
  }


  public isLightThemeEnabled()
  {
    return this.lightsOn;
  }
}
