export class PermitSetupModel {

  assignToLocation: boolean = true;
  assignToLocationGroup: boolean = false;

  readableFixedExpiryDateTime: string;
  selectedLocationGroupUid: any = null;
  selectedLocationUid: any = null;
  kioskLocationUid: any = null;
  requestDriverName: boolean = false;
  addAppInfo: boolean = false;
  appInfo: string = null;
  requestAppInfo = false;

  siteName: string;
  
  noReturnWithinMinutes: number;
  maxStayCriteria: number;
  maxStayMinutes: number;
  maxStayTime: string;
  minStartTime: string;
  minStartDate: string;

  configTimestamp: string;
  containsCharge: boolean;
  prebookingEnabled: boolean;
  preBookLimitNumber: number;
  preBookLimitType: string;

  savedOnDevice: boolean;
  
  constructor() {

  }
}