import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiService } from '../api/api.service';
import { AppearanceService } from '../appearance/appearance.service';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { PrinterConfigModel } from 'src/app/models/printer-config.model';
import { Constants } from 'src/app/constants/constants';

declare var Socket: any;


@Injectable({
  providedIn: 'root'
})
export class PrinterService {

  printerConfig: PrinterConfigModel = null;


  constructor(private apiService: ApiService, private localStorageService: LocalStorageService, 
    private appearanceService: AppearanceService) 
  { 
    //this.initService();
  }

    /*
  initService()
  {

  }


  public loadFromLocalData() 
  {
    this.printerConfig = this.localStorageService.getPrinterConfig();

    if (!this.printerConfig)
    {
      this.printerConfig = new PrinterConfigModel();
      this.printerConfig.ip = null;
      this.printerConfig.port = null;
      this.printerConfig.connected = false;
      this.printerConfig.status = "Disconnected";
      this.localStorageService.savePrinterConfig(this.printerConfig);
    }
  }


  public logout() 
  {

    this.printerConfig.ip = null;
    this.printerConfig.port = null;
    this.printerConfig.connected = false;
    this.printerConfig.status = "Disconnected";
    this.localStorageService.savePrinterConfig(this.printerConfig);
  }


  // -------------------------------------------------------------------------


  public connectPrinter(ip, port)
  {
    if (!ip || !port)
    {
      this.appearanceService.presentToast("Please enter IP and PORT.", Constants.TOAST_ERROR);
      return;
    }

    this.printerConfig.ip = ip;
    this.printerConfig.port = port;
    this.printerConfig.connected = false;
    this.printerConfig.status = "Connecting";
    this.localStorageService.savePrinterConfig(this.printerConfig);

    this.testPrinter();
  }


  public disconnectPrinter()
  {
    this.logout();
  }


  testPrinter()
  {
    if (!this.printerConfig)
    {
      return;
    }
    
    if (!this.printerConfig.ip || !this.printerConfig.port)
    {
      return;
    }

    const socket = new Socket();

    // socket receive bytecode, therefore we need to create a byte stream by using esc-pos-encoder-ionic
    const encoder = new EscPosEncoder();
    const result = encoder.initialize();

    result
      .align('center')
      .newline()
      .line('Congratulation, print success')
      .line('IP : ' + this.printerConfig.ip)
      .line('Port : ' + this.printerConfig.port)
      .newline()
      .newline()
      .newline()
      .newline()
      .newline()
      .newline()
      .cut();

    const resultByte = result.encode();

    // send byte code into the printer
    socket.open(
      this.printerConfig.ip,
      this.printerConfig.port,
      () => {
        socket.write(resultByte, () => {

          this.printerConfig.connected = true;
          this.printerConfig.status = "Connected";
          this.localStorageService.savePrinterConfig(this.printerConfig);
          socket.shutdownWrite();
        });
      },
      (err) => {
        
        this.printerConfig.connected = false;
        this.printerConfig.status = "Disconnected";
        this.localStorageService.savePrinterConfig(this.printerConfig);
        this.appearanceService.presentToast("Printing Failed.", Constants.TOAST_ERROR);
      }
    );
  }



  public getPrinterStatus(): string
  {
    if (this.printerConfig)
    {
      return this.printerConfig.status;
    }

    return "Unknown";
  }


  public isPrinterConnected(): boolean
  {
    if (this.printerConfig)
    {
      return this.printerConfig.connected;
    }
    
    return false;
  }
  */
}
