import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { ClientService } from './services/client/client.service';
import { AppearanceService } from './services/appearance/appearance.service';
import { DeviceService } from './services/device/device.service';
import { PermitService } from './services/permit/permit.service';
import { LocalStorageService } from './services/local-storage/local-storage.service';
import { Router } from '@angular/router';
import { TerminalService } from './services/terminal/terminal.service';
import { PrinterService } from './services/printer/printer.service';
import { QrService } from './services/qr/qr.service';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private localStorageService: LocalStorageService,
    private deviceService: DeviceService,
    private clientService: ClientService,
    private appearanceService: AppearanceService,
    private permitService: PermitService,
    private terminalService: TerminalService,
    private printerService: PrinterService,
    private qrService: QrService,
    private statusBar: StatusBar,
    private router: Router
  ) {
    this.initializeApp();
  }

  initializeApp() {

    moment.tz.setDefault('Europe/London');

    this.platform.ready().then(() => {

      this.platform.resume.subscribe(async () => {

        this.pingApi();
      });

      this.listenToEvents();

    });
  }


  listenToEvents()
  {
    this.appearanceService.listenForEvent('user:loggedin', (data: any) => {

      this.pingApi();

    });

    this.appearanceService.listenForEvent('user:loggedout', (data: any) => {

      this.router.navigateByUrl('/tabs/login');

    });
  }


  pingApi()
  {
    if (this.clientService.isUserLoggedIn())
    {  
      this.clientService.pingApi();
    }
  }
}
