export class DeviceModel {

  deviceUid: number;
  deviceUuid: string;
  deviceModel: string;
  deviceType: string;
  deviceOs: string;

  constructor() 
  {

  }
}