export class CheckoutRequestModel {

  uniqueRef: string;
  checkoutTypeUid: number;
  checkoutReg: string;
  clientUid: number;
  noticeUid: number;
  pricingRuleUid: number;
  rewardUid: number;
  unlockedRewardUid: number;
  checkoutAmount: string;
  locationUid: string;
  locationGroupUid: string;
  locationName: string;
  appInfo: string;
  userUid: any;
  stripeConnectAccount: string;
  isPaymentPlan: boolean;
  isFreeCheckout: boolean;
  manualStartDateTime: string;
  anprEntranceFound: boolean;
  anprEntranceTimestamp: string;
  anprEntranceEventUid: string;
  anprEntranceDateTime: string;
  activePermitName: any;
  success: boolean;
  errorMessage: string;
  discountAmount: string;
  driverFeeText: string;
  permitHasExpired: boolean;
  base64Overview: string;
  startDateTime: string;
  expiryDateTime: string;
  whitelistUid: number;
  driverName: string;
  driverEmail: string;
  driverPhone: string;
  driverAddressLine: string;
  driverPostCode: string;
  actionSuccessMessage: string;
  noReturnMessage: string;
  paymentMethodId: string;
  paymentIntentId: string;
  paymentIntentClientSecret: string;
  pendingTransactionUid: number;
  pendingSubscriptionUid: number;
  subscriptionId: string;
  invoiceUid: number;
  isInvoiceForUnpaidPlan: boolean;
  invoiceTextFallbackUid: string;
  platformCustomerId: string;
  nextPaymentDateTime: string;
  currency: string = "gbp";
  paymentSourceCheckoutUid: number;
  paymentSourcePlatformUid: number;
  paymentSourceNativeUid: number;
  paymentSourceExtraUid: number;
  paymentSourceTypeUid: number;
  updateSubscriptionPaymentMethod: boolean;
  usingGooglePay: boolean;
  usingApplePay: boolean;
  visitorMainPermitUid: number;
  visitorCustomPermitTypeUid: number;
  prebookingEnabledAtSite: boolean;
  clientCode: string;
  subClientUid: number;
  permitApplicationUid: number;
  permitId: string;
  readableCheckout: string;
  checkoutRoute: string;
  deviceUid: number;
  deviceUuid: string;
  renewingPermitUid: number;
  terminalPresent: boolean;
  
  constructor() {

    this.renewingPermitUid = null;
    this.noticeUid = null;
  }
}