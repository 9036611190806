import { RedeemableRewardModel } from "./redeemable-reward.model";

export class PriceRuleModel {

  uid: number;
  ruleName: string;
  startDateTime: string;
  endDateTime: string;
  preBookMinStartTime: string;
  preBookMinStartDate: string;
  mon: boolean;
  tue: boolean;
  wed: boolean;
  thur: boolean;
  fri: boolean;
  sat: boolean;
  sun: boolean;
  useDateRange: boolean;
  useTimeRange: boolean;
  renewAnnualy: boolean;
  bankHolidayRule: boolean;
  incrementalRule: boolean;
  incrementalMinutes: number;
  incrementalPrice: string;
  name: string;
  price: string;
  tierRule: number;
  readable: string;
  fromMinutes: number;
  toMinutes: number;
  exceededMinutes: number;
  durationCriteria: number;
  isActive: boolean;
  parkUntilTime: string;

  actionCheckoutMessage: string;
  actionSuccessMessage: string;
  actionRule: boolean;

  reward: RedeemableRewardModel;

  rewardUid: number;
  unlockedRewardUid: number;
  rewardPricePence: string;
  
  constructor() 
  {

  }
}
