export class PrinterConfigModel {

  ip: string;
  port: string;
  connected: boolean;
  status: string;

  constructor() 
  {

  }
}