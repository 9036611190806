export class CheckoutResponseModel {

  transactionUid: number;
  success: boolean;

  actionSuccessMessage: string;
  
  checkoutSite: string;
  checkoutReg: string;
  checkoutAmount: string;
  checkoutAuthority: string;
  transactionRef: string;
  permitUid: string;
  permitId: string;

  permitStartDateTime: string;
  permitEndDateTime: string;
  permitStartDateTimeReadable: string;
  permitEndDateTimeReadable: string;


  constructor() {


  }
}