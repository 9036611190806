import { Injectable } from '@angular/core';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { catchError, map } from 'rxjs/operators';

import { AppearanceService } from '../appearance/appearance.service';
import { ApiService } from '../api/api.service';
import { ClientService } from '../client/client.service';

import { TerminalConfig } from 'src/app/models/terminal-config.model';
import { TerminalStripeModel } from 'src/app/models/terminal-stripe.model';

import { Constants } from 'src/app/constants/constants';
import { throwError } from 'rxjs';

declare var StripeTerminal;


@Injectable({
  providedIn: 'root'
})
export class TerminalService {

  terminalConfig: TerminalConfig = null;
  terminal: any;
  loading: boolean = false;


  constructor(private localStorageService: LocalStorageService, private clientService: ClientService,
    private appearanceService: AppearanceService, private apiService: ApiService) 
  { 

    this.appearanceService.listenForEvent('storage:loaded', (data: any) => {

      this.loadFromLocalData();

    });
  }


  loadFromLocalData() 
  {
    this.terminalConfig = this.localStorageService.getTerminalConfig();

    if (!this.terminalConfig)
    {
        this.terminalConfig = new TerminalConfig();
        this.terminalConfig.connected = false;
        this.terminalConfig.init = false;
        this.terminalConfig.locationUid = null;
        this.terminalConfig.shouldReconnect = false;
        this.terminalConfig.status = null;
        this.terminalConfig.stripeReaderObject = null;
        this.terminalConfig.deviceType = null;
        this.terminalConfig.paymentInProgress = false;
        this.terminalConfig.canBeCancelled = true;
    }
    
    if (this.terminalConfig.locationUid)
    {
      this.initStripeTerminal();
    }
  }


  public logout() 
  {

  }


  public registerCardReader(pairingKey)
  {
    if (!pairingKey)
    {
      this.loading = false;
      return;
    }

    var data = {
      ClientUid: this.clientService.getClientUid(),
      SubClientUid: this.clientService.getSubClientUid(),
      LocationUid: this.terminalConfig.locationUid,
      ReaderRegistrationCode: pairingKey
    };

    return this.apiService.registerCardReader(data).pipe(map((response: any) => {

      if (response.Success)
      {
        return response;
      }
      else
      {
        if (response.Expired)
        {
          this.appearanceService.presentToast("Pairing Code has expired.", Constants.TOAST_INFO);
        }
      }

      return null;
    }),
    catchError((err) => { 
      
      return throwError(() => null);
  
    }));
  }


  public setTerminalLocationUid(locUid)
  {
    if (locUid != this.terminalConfig.locationUid)
    {
      this.terminalConfig.locationUid = locUid;
      this.localStorageService.saveTerminalConfig(this.terminalConfig);

      if (this.terminalConfig.init)
      {
        // TODO - Disconnect and Re Init.
      }
    }

    if (!this.terminalConfig.init)
    {
      this.initStripeTerminal();
    }
  }


  async initStripeTerminal()
  {
    this.loading = true;

    this.terminalConfig.init = false;
    this.terminalConfig.connected = false;
    this.localStorageService.saveTerminalConfig(this.terminalConfig);

    if (!this.terminalConfig.locationUid)
    {
      this.loading = false;
      return;
    }

    const promise = new Promise((resolve, reject) => {
        
      return this.getCardReaderTokenFromApi(this.terminalConfig.locationUid).subscribe(response => {
    
        resolve(response);
      },
      err => {

        reject(null);
      });
    });

    const token = await promise;

    if (!token)
    {
      this.loading = false;
      return;
    }

    this.terminal = await StripeTerminal.create({
      // 1c. Create a callback that retrieves a new ConnectionToken from the example backend
      onFetchConnectionToken: () => {
        // TODO - Need a way to tell App that token was returned otherwise we might need to reinit...
        return token;
      },
      // 1c. (Optional) Create a callback that will be called if the reader unexpectedly disconnects.
      // You can use this callback to alert your user that the reader is no longer connected and will need to be reconnected.
      onUnexpectedReaderDisconnect: () => {
          this.unexpectedDisconnect();
      },
      // 1c. (Optional) Create a callback that will be called when the reader's connection status changes.
      // You can use this callback to update your UI with the reader's connection status.
      onConnectionStatusChange: (ev) => {

          if (ev.status == "not_connected")
          {
            this.terminalConfig.status = "disconnected";
            this.terminalConfig.connected = false;
          }
          else
          {
            this.terminalConfig.status = ev.status;
          }

          this.localStorageService.saveTerminalConfig(this.terminalConfig);
        }
      }
    );

    if (this.terminal)
    {
      this.terminalConfig.init = true;
      this.loading = false;

      if (this.terminalConfig.shouldReconnect)
      {
        this.loading = true;
        this.connectToCardReader(null);
      }
    }
  }


  public getCardReaderTokenFromApi(locationUid)
  {
    var data = {
      ClientUid: this.clientService.getClientUid(),
      SubClientUid: this.clientService.getSubClientUid(),
      LocationUid: locationUid
    };

    return this.apiService.getCardReaderToken(data).pipe(map((response: any) => {
      
      if (response.Success)
      {
        return response.Token;
      }

      return null;
    }),
    catchError((err) => { 
      
      return throwError(() => null);
  
    }));
  }


  public capturePaymentIntentWithApi(paymentIntentId)
  {
    var data = {
      client_uid: this.clientService.getClientUid(),
      sub_client_uid: this.clientService.getSubClientUid(),
      payment_intent_id: paymentIntentId,
      connect_account_id: this.clientService.getClientStripeAccountId()
    };
    
    return this.apiService.terminalCapturePaymentIntent(data).pipe(map((response: any) => {
      
      return response;
    }),
    catchError((err) => { 
      
      return throwError(() => err);
  
    }));
  }


  async unexpectedDisconnect()
  {
    this.terminalConfig.connected = false;
    this.localStorageService.saveTerminalConfig(this.terminalConfig);
  }


  connectToCardReader(pairingKey)
  {
    //if (this.clientService.isDevMode())
    //{
    //  this.connectToReaderSimulator();
    //}
    //else
    //{
      this.loading = true;
      this.registerAndConnectCardReader(pairingKey);
    //}
  }


  async registerAndConnectCardReader(pairingKey)
  {
    if (this.terminalConfig.stripeReaderObject)
    {
      await this.connectToReader(this.terminalConfig.stripeReaderObject);
      return;
    }

    if (!pairingKey)
    {
      this.loading = false;
      return;
    }

    const promise = new Promise<any>((resolve, reject) => {
        
      return this.registerCardReader(pairingKey).subscribe(response => {
    
        resolve(response);
      },
      err => {

        reject(null);
      });
    });

    const token = await promise;

    if (token)
    {
      if (token.Success)
      {
        this.connectPhysicalReader(token.ReaderObject);
      }
      else
      {
        this.loading = false;
        this.appearanceService.presentToast(token.Message, Constants.TOAST_ERROR)
      }
    }
    else
    {
      this.loading = false;
      this.appearanceService.presentToast("Error connecting card reader.", Constants.TOAST_ERROR)
    }
  }


  async connectPhysicalReader(stripeReaderObject)
  {
    this.loading = true;
    this.terminalConfig.stripeReaderObject                    = new TerminalStripeModel();
    this.terminalConfig.stripeReaderObject.device_sw_version  = stripeReaderObject.device_sw_version;
    this.terminalConfig.stripeReaderObject.device_type        = stripeReaderObject.device_type;
    this.terminalConfig.stripeReaderObject.id                 = stripeReaderObject.id;
    this.terminalConfig.stripeReaderObject.ip_address         = stripeReaderObject.ip_address;
    this.terminalConfig.stripeReaderObject.label              = stripeReaderObject.label;
    this.terminalConfig.stripeReaderObject.livemode           = stripeReaderObject.livemode;
    this.terminalConfig.stripeReaderObject.location           = stripeReaderObject.location;
    this.terminalConfig.stripeReaderObject.metadata           = stripeReaderObject.metadata;
    this.terminalConfig.stripeReaderObject.object             = stripeReaderObject.object;
    this.terminalConfig.stripeReaderObject.serial_number      = stripeReaderObject.serial_number;
    this.terminalConfig.stripeReaderObject.status             = stripeReaderObject.status;

    this.terminalConfig.deviceType = this.terminalConfig.stripeReaderObject.device_type
    this.localStorageService.saveTerminalConfig(this.terminalConfig);

    await this.connectToReader(this.terminalConfig.stripeReaderObject);
  }


  isLoading(): boolean
  {
    return this.loading;
  }


  async connectToReaderSimulator() 
  {
    const simulatedResult = await this.terminal.discoverReaders({
      simulated: true,
    });

    await this.connectToReader(simulatedResult.discoveredReaders[0]);
  }
  

  //https://github.com/stripe/stripe-terminal-js-demo/blob/master/src/MainPage.jsx
  async connectToReader(selectedReader) 
  {
    const connectResult = await this.terminal.connectReader(selectedReader);
    
    if (connectResult.error) 
    {
      this.loading = false;
      this.terminalConfig.shouldReconnect = false;

      if (connectResult.error)
      {
        this.terminalConfig.stripeReaderObject = null;
        this.terminalConfig.init = false;
        this.terminalConfig.connected = false;
      }

      this.localStorageService.saveTerminalConfig(this.terminalConfig);
      
      this.appearanceService.presentToast(connectResult.error.message, Constants.TOAST_ERROR);
    } 
    else 
    {
      this.loading = false;

      if (this.clientService.isDevMode())
      {
        this.appearanceService.presentToast("Simulator Card Reader Connected", Constants.TOAST_INFO);
      }
      else
      {
        this.appearanceService.presentToast(this.getCardReaderName() + " Connected", Constants.TOAST_INFO);
      }

      this.terminalConfig.shouldReconnect = true;
      this.terminalConfig.connected = true;
      this.localStorageService.saveTerminalConfig(this.terminalConfig);
      return connectResult;
    }
  };


  async disconnectCardReader()
  {
    const connectResult = await this.terminal.disconnectReader();
    
    if (connectResult.error) 
    {
      console.log("Failed to disconnect:", connectResult.error);
    } 
    else 
    {
      this.terminalConfig.init = false;
      this.terminalConfig.shouldReconnect = false;
      this.terminalConfig.connected = false;
      this.terminalConfig.stripeReaderObject = null;
      this.terminalConfig.deviceType = null;
      this.terminalConfig.paymentInProgress = false;
      this.terminalConfig.canBeCancelled = true;
      this.localStorageService.saveTerminalConfig(this.terminalConfig);
      return connectResult;
    }
  }


  async cancelPayment()
  {
    this.terminal.cancelCollectPaymentMethod(); 
    this.terminal.clearReaderDisplay();
  }


  paymentComplete()
  {
    this.terminalConfig.canBeCancelled = true;
    this.terminalConfig.paymentInProgress = false;
    this.localStorageService.saveTerminalConfig(this.terminalConfig);
  }


  async takeCardPayment(paymentIntentClientSecret)
  {
    this.terminalConfig.canBeCancelled = true;
    this.localStorageService.saveTerminalConfig(this.terminalConfig);
    
    if (this.clientService.isDevMode())
    {
    //  this.terminal.setSimulatorConfiguration({
    //    testPaymentMethod: "visa",
    //    testCardNumber: "4242424242424242",
    //  });
    }

    const paymentMethodPromise = this.terminal.collectPaymentMethod(
      paymentIntentClientSecret
    );

    const result = await paymentMethodPromise;

    if (result.error) 
    {
      return { Success: false, Message: result.error.message };
    } 
    else 
    {
      this.terminalConfig.canBeCancelled = false;
      this.localStorageService.saveTerminalConfig(this.terminalConfig);

      const confirmResult = await this.terminal.processPayment(
        result.paymentIntent
      );

      // At this stage, the payment can no longer be canceled because we've sent the request to the network.

      if (confirmResult.error) 
      {
        return { Success: false, Message: confirmResult.error.message };
      } 
      else if (confirmResult.paymentIntent) 
      {
        return { Success: true, PaymentIntentId: confirmResult.paymentIntent.id };
      }
    }
  }


  async updateReaderDisplay(vrm, amount)
  {
    if (!this.terminal)
    {
      this.appearanceService.presentToast("Card Reader Disconnected", Constants.TOAST_ERROR)
      return;
    }

    var stripeAmount = Number(amount) * 100;

    await this.terminal.setReaderDisplay({
      type: "cart",
      cart: {
        line_items: [
          {
            description: "Vehicle: " + vrm,
            amount: stripeAmount,
            quantity: 1
          }
        ],
        tax: null,
        total: stripeAmount,
        currency: "gbp"
      }
    });
  }


  public getReaderStatus()
  {
    if (this.terminal)
    {
      if (this.terminalConfig.status)
      {
        return this.terminalConfig.status;
      }
      else
      {
        return "disconnected";
      }
    }

    return "";
  }


  public isCardReaderConnected(): boolean
  {
    if (this.terminalConfig.status)
    {
      if (this.terminalConfig.status == "connected")
      {
        return true;
      }
    }

    return false;
  }


  public isReaderInitialised(): boolean
  {
    if (!this.terminal)
    {
      if (this.terminalConfig)
      {
        this.terminalConfig.init = false;
        this.terminalConfig.connected = false;
      }
      
      return false;
    }

    if (this.terminalConfig)
    {
      if (this.terminalConfig.init)
      {
        return true;
      }
    }

    return false;
  }


  public getCardReaderName(): string
  {
    if (this.terminalConfig)
    {
      if (this.terminalConfig.deviceType)
      {
        return this.terminalConfig.deviceType;
      }
    }

    return "Card Reader";
  }


  /*
  getCardReaderToken(): any
  {
    var promise = new Promise((resolve, reject) => {
        
      return this.getCardReaderTokenFromApi(this.terminalLocationUid).subscribe(response => {

        this.appearanceService.triggerEvent('terminal:token', {
          time: new Date()
        });

        resolve(response);
      },
      err => {

        reject(null);
      });
    });

    return promise;
  }
  */
}
