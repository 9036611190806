import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiService } from '../api/api.service';
import { AppearanceService } from '../appearance/appearance.service';
import { LocalStorageService } from '../local-storage/local-storage.service';

declare var Socket: any;


@Injectable({
  providedIn: 'root'
})
export class QrService {


  constructor(private apiService: ApiService, private localStorageService: LocalStorageService, 
    private appearanceService: AppearanceService) 
  { 
    this.initService();
  }


  initService()
  {

  }


  public loadFromLocalData() 
  {
    
  }


  public logout() 
  {

  }


  // -------------------------------------------------------------------------


  public isQrReaderConnected(): boolean
  {    
    return true;
  }
}
