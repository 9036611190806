import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map  } from 'rxjs/operators';
import { Constants } from 'src/app/constants/constants';

@Injectable({
  providedIn: 'root'
})
export class BaseService {
  constructor(public http: HttpClient) {}

  public id: string = "";
  public baseUrl: String = "";
  public localHostUrl: String = 'localhost';

  getData(url) {
    return this.call('GET', url)
  }

  saveData(url, payload) {

    payload.AppVesion = Constants.APP_VERSION;
    return this.call('POST', url, payload)
  }

  deleteData(url) {
    return this.call('DELETE', url)
  }

  putData(url, payload) {
    return this.call('PUT', url, payload)
  }


  saveDataTerminal(url, payload) {
    return this.terminalCall('POST', url, payload)
  }

  getDataTerminal(url) {
    return this.terminalCall('GET', url)
  }

  deleteDataTerminal(url) {
    return this.terminalCall('DELETE', url)
  }

  putDataTerminal(url, payload) {
    return this.terminalCall('PUT', url, payload)
  }


  call(method, url, payload?) {
    let requestOptions = { headers: new HttpHeaders().set('id', this.id) }

    if (method === 'POST') {

        return this.http.post(`${this.baseUrl}${url}`, JSON.stringify(payload), requestOptions)
          .pipe(map(res => res))
    }

    if (method === 'GET') {

      return this.http.get(`${this.baseUrl}${url}`, requestOptions)
      .pipe(map(res => res))
    }

    if (method === 'PUT') {
      return this.http.put(`${this.baseUrl}${url}`, JSON.stringify(payload), requestOptions)
        .pipe(map(res => res))
    }

    if (method === 'DELETE') {
      return this.http.delete(`${this.baseUrl}${url}`, requestOptions)
        .pipe(map(res => res))
    }
  }


  terminalCall(method, url, payload?) {
    let requestOptions = { headers: new HttpHeaders().set('id', this.id) }

    if (method === 'POST') {

        return this.http.post(`${this.localHostUrl}${url}`, JSON.stringify(payload), requestOptions)
          .pipe(map(res => res))
    }

    if (method === 'GET') {

      return this.http.get(`${this.localHostUrl}${url}`, requestOptions)
      .pipe(map(res => res))
    }

    if (method === 'PUT') {
      return this.http.put(`${this.localHostUrl}${url}`, JSON.stringify(payload), requestOptions)
        .pipe(map(res => res))
    }

    if (method === 'DELETE') {
      return this.http.delete(`${this.localHostUrl}${url}`, requestOptions)
        .pipe(map(res => res))
    }
  }
}
