import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';

import { ApiService } from '../api/api.service';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { ClientService } from '../client/client.service';
import { DeviceService } from '../device/device.service';
import { AppearanceService } from '../appearance/appearance.service';

import { PermitSetupModel } from 'src/app/models/permit-setup.model';
import { CheckoutRequestModel } from 'src/app/models/checkout-request.model';
import { CheckoutResponseModel } from 'src/app/models/checkout-response.model';
import { PriceRuleModel } from 'src/app/models/price-rule.model';

import { Constants } from '../../constants/constants';
import * as moment from 'moment-timezone';
import { throwError } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class PermitService {

  private permitSetup: PermitSetupModel = null;
  private pricingTierRules: Array<PriceRuleModel> = Array<PriceRuleModel>();
  private actionRules: Array<PriceRuleModel> = Array<PriceRuleModel>();
  private checkoutRequest: CheckoutRequestModel = null;
  private checkoutResponse: CheckoutResponseModel = null;
  private selectedRule: PriceRuleModel = null;

  driverName: string = null;
  appInfo: string = null;
  vehicleReg: string = null;



  constructor(private apiService: ApiService, private localStorageService: LocalStorageService, 
    private appearanceService: AppearanceService, private clientService: ClientService, 
    private deviceService: DeviceService) 
  { 
    this.initService();
  }


  initService()
  {
    this.appearanceService.listenForEvent('storage:loaded', (data: any) => {

      this.loadFromLocalData();

    });

    this.appearanceService.listenForEvent('user:loggedout', (data: any) => {

      this.logout();

    });
  }


  public loadFromLocalData() 
  {
    this.permitSetup = this.localStorageService.getPermitSetup();
  }


  public logout() 
  {
    this.permitSetup = null;
    this.localStorageService.savePermitSetup(this.permitSetup);
  }


  // API Calls -------------------------------------------------------------------------


  public checkSecretKeyAndConfig(secret, clientUid): any
  {
    var payload = {
      Key: secret,
      ClientUid: clientUid,
      DeviceUid: this.deviceService.getDeviceUid(),
      ConfigTimeStamp: this.permitSetup.configTimestamp,
      DeviceUuid: this.deviceService.getDeviceUuid(),
      AppVersion: Constants.APP_VERSION,
      DeviceOs: this.deviceService.getDeviceOs(),
      DeviceModel: this.deviceService.getDeviceModel(),
      DeviceType: this.deviceService.getDeviceType(),
      SubClientUid: this.clientService.getSubClientUid()
    }

    return this.apiService.checkSecretKeyAndConfig(payload).pipe(map((response: any) => {

      if (response)
      {
        if (response.DeviceUid)
        {
          this.clientService.setDeviceUid(response.DeviceUid);
        }

        if (response.LatestAppVersion)
        {
          this.clientService.saveLatestAppVersion(response.LatestAppVersion);
        }

        if (response.Success)
        {
          if (response.UpdateConfig)
          {
            this.updateKioskConfigFromPortalApi(response.Config);
          }
        }
      }
      
      return response;
    }),
    catchError((err) => { 
      
      return throwError(() => err);
  
    }));
  }


  public updateKioskConfigFromPortalApi(apiConfig)
  {            
    var newKioskLocationSiteName = "";

    if (this.permitSetup.kioskLocationUid != apiConfig.KioskLocationUid)
    {
      var allLocations = this.clientService.getAllLocations();
      for (var i = 0; i < allLocations.length; i++)
      {
        if (allLocations[i].locationUid == apiConfig.KioskLocationUid)
        {
          newKioskLocationSiteName = allLocations[i].name;
          break;
        }
      }        
    }

    if (apiConfig.PermitLocationUid)
    {
      var foundLocation = null;
      var allLocations = this.clientService.getLocationsWithPricing();

      for (var i = 0; i < allLocations.length; i++)
      {
        if (allLocations[i].locationUid == apiConfig.PermitLocationUid)
        {
          foundLocation = allLocations[i];

          this.permitSetup = new PermitSetupModel();

          this.permitSetup.assignToLocation         = true;
          this.permitSetup.assignToLocationGroup    = false;
          this.permitSetup.selectedLocationGroupUid = null;
          this.permitSetup.selectedLocationUid      = apiConfig.PermitLocationUid;
          this.permitSetup.noReturnWithinMinutes    = foundLocation.noReturnWithinMinutes;
          this.permitSetup.maxStayCriteria          = foundLocation.maxStayCriteria;
          this.permitSetup.maxStayMinutes           = foundLocation.maxStayMinutes;
          this.permitSetup.maxStayTime              = foundLocation.maxStayTime;
          this.permitSetup.minStartTime             = foundLocation.minStartTime;
          this.permitSetup.minStartDate             = foundLocation.minStartDate;
          this.permitSetup.preBookLimitNumber       = foundLocation.preBookLimitNumber;
          this.permitSetup.preBookLimitType         = foundLocation.preBookLimitType;
          break;
        }
      }

      if(!foundLocation)
      {
          this.appearanceService.presentToast("Invalid Remote Location Setup.", Constants.TOAST_ERROR);
          
          this.saveKioskSetup(this.permitSetup).subscribe(response => {            
          },
          err => {  
            this.appearanceService.presentToast(err, Constants.TOAST_ERROR);          
          });

          return;
      }
    }

    if (apiConfig.PermitLocationGroupUid)
    {
      var foundGroup = null;
      var allGroups = this.clientService.getLocationGroups();

      for (var i = 0; i < allGroups.length; i++)
      {
        if (allGroups[i].locationGroupUid == apiConfig.PermitLocationGroupUid)
        {
          foundGroup = allGroups[i];
          
          this.permitSetup = new PermitSetupModel();

          this.permitSetup.assignToLocation         = false;
          this.permitSetup.assignToLocationGroup    = true;
          this.permitSetup.selectedLocationUid      = null;
          this.permitSetup.selectedLocationGroupUid = apiConfig.PermitLocationGroupUid;
          this.permitSetup.noReturnWithinMinutes    = foundGroup.noReturnWithinMinutes;
          this.permitSetup.maxStayCriteria          = foundGroup.maxStayCriteria;
          this.permitSetup.maxStayMinutes           = foundGroup.maxStayMinutes;
          this.permitSetup.maxStayTime              = foundGroup.maxStayTime;
          this.permitSetup.minStartTime             = foundGroup.minStartTime;
          this.permitSetup.minStartDate             = foundGroup.minStartDate;
          this.permitSetup.containsCharge           = foundGroup.containsCharge;
          this.permitSetup.prebookingEnabled        = foundGroup.preBookingEnabled;
          this.permitSetup.preBookLimitNumber       = foundGroup.preBookLimitNumber;
          this.permitSetup.preBookLimitType         = foundGroup.preBookLimitType;
          break;
        }
      }

      if(!foundGroup)
      {
          this.appearanceService.presentToast("Invalid Remote Location Group Setup.", Constants.TOAST_ERROR);

          this.saveKioskSetup(this.permitSetup).subscribe(response => {              
          },
          err => {  
            this.appearanceService.presentToast(err, Constants.TOAST_ERROR);          
          });

          return;
      }
    }
    
    this.permitSetup.configTimestamp = apiConfig.ConfigTimeStamp;
    this.permitSetup.kioskLocationUid = apiConfig.KioskLocationUid;

    if (newKioskLocationSiteName)
    {
      this.permitSetup.siteName = newKioskLocationSiteName;
    }

    if (apiConfig.RequestDriverName == "1")
    {
      this.permitSetup.requestDriverName = true;
    }
    else
    {
      this.permitSetup.requestDriverName = false;
    }

    if (apiConfig.AppAppInfo == "1")
    {
      this.permitSetup.addAppInfo = true;

      if (apiConfig.RequestAppInfo == "1")
      {
        this.permitSetup.appInfo = null;
        this.permitSetup.requestAppInfo = true;
      }
      else
      {
        this.permitSetup.appInfo = apiConfig.AppInfo;
        this.permitSetup.requestAppInfo = false;
      }
    }
    else
    {
      this.permitSetup.addAppInfo = false;
      this.permitSetup.appInfo = null;
      this.permitSetup.requestAppInfo = false;
    }

    this.localStorageService.savePermitSetup(this.permitSetup);
  }


  public saveKioskSetup(permitSetup): any
  {
    permitSetup.configTimestamp = moment().unix();

    var data = {
      KioskLocationUid: permitSetup.kioskLocationUid, 
      PermitLocationUid: permitSetup.selectedLocationUid, 
      PermitLocationGroupUid: permitSetup.selectedLocationGroupUid, 
      RequestDriverName: permitSetup.requestDriverName, 
      AppAppInfo: permitSetup.addAppInfo, 
      DeviceUid: this.deviceService.getDeviceUid(),
      AppInfo: permitSetup.appInfo,
      RequestAppInfo: permitSetup.requestAppInfo,
      ConfigTimeStamp: permitSetup.configTimestamp,
      DeviceUuid: this.deviceService.getDeviceUuid(),
      AppVersion: Constants.APP_VERSION,
      DeviceOs: this.deviceService.getDeviceOs(),
      DeviceModel: this.deviceService.getDeviceModel(),
      DeviceType: this.deviceService.getDeviceType(),
      ClientUid: this.clientService.getClientUid(),
      SubClientUid: this.clientService.getSubClientUid()
    }

    return this.apiService.saveKioskSetup(data).pipe(map((response: any) => {    

      if (response.DeviceUid)
      {
        this.deviceService.updateDeviceUid(response.DeviceUid);
      }

      if (response.Success)
      {
        this.permitSetup = permitSetup;
        this.permitSetup.savedOnDevice = true;
        this.localStorageService.savePermitSetup(this.permitSetup);

        this.clientService.saveLatestAppVersion(response.LatestAppVersion);
      }

      return response;
    }),
    catchError((err) => { 
      
      return throwError(() => err);
  
    }));
  }


  public checkForNoReturnMinutesAndAnprEntranceAndActivePermit(prebookDateTime): any
  {
    var data = {
      Registration: this.getEnterVehicleReg(),
      LocationUid: this.permitSetupGetSelectedLocationUid(),
      LocationGroupUid: this.permitSetupGetSelectedLocationGroupUid(),
      ClientUid: this.clientService.getClientUid(),
      SubClientUid: this.clientService.getSubClientUid(),
      PrebookDateTime: prebookDateTime
    };

    return this.apiService.checkForNoReturnMinutesAndAnprEntranceAndActivePermit(data).pipe(map((response: any) => {

      this.pricingTierRules = Array<PriceRuleModel>();
      this.actionRules      = Array<PriceRuleModel>();
      this.checkoutRequest = new CheckoutRequestModel();

      this.checkoutRequest.anprEntranceFound      = false;

      if (response.Success)
      {
        this.checkoutRequest.success = true;

        // Save these to keep them up to date, in case Kiosk has become Unsynced.
        this.permitSetup.maxStayCriteria        = Number(response.MaxStayCriteria);
        this.permitSetup.maxStayMinutes         = response.MaxStayMinutes;
        this.permitSetup.maxStayTime            = response.MaxStayTime;
        this.permitSetup.noReturnWithinMinutes  = response.NoReturnWithinMin;
        this.permitSetup.prebookingEnabled      = response.PrebookEnabled;
        this.permitSetup.preBookLimitNumber     = Number(response.PreBookLimitNumber);
        this.permitSetup.preBookLimitType       = response.PreBookLimitType;
        this.savePermitSetup(this.permitSetup);
      
        for (var x = 0; x < response.ParkingRules.length; x++)
        {
          var rule = response.ParkingRules[x];

          var priceRule                   = new PriceRuleModel();
          priceRule.ruleName              = rule.RuleName;
          priceRule.uid                   = Number(rule.PricingTierRuleUid);
          priceRule.startDateTime         = rule.StartDateTime;
          priceRule.endDateTime           = rule.EndDateTime;
          priceRule.preBookMinStartTime   = rule.RuleMinStartTime;
          priceRule.preBookMinStartDate   = rule.RuleMinStartDate;
          priceRule.mon                   = Boolean(rule.Monday);
          priceRule.tue                   = Boolean(rule.Tuesday);
          priceRule.wed                   = Boolean(rule.Wednesday);
          priceRule.thur                  = Boolean(rule.Thursday);
          priceRule.fri                   = Boolean(rule.Friday);
          priceRule.sat                   = Boolean(rule.Saturday);
          priceRule.sun                   = Boolean(rule.Sunday);
          priceRule.renewAnnualy          = Boolean(rule.RenewAnnually);
          priceRule.name                  = rule.Name;
          priceRule.price                 = rule.Price;
          priceRule.tierRule              = rule.LocationPricingTierRule;
          priceRule.incrementalRule       = Boolean(rule.IncrementalCharge);
          priceRule.incrementalMinutes    = rule.IncrementalMinutes;
          priceRule.parkUntilTime         = rule.ParkUntilTime;
          priceRule.incrementalPrice      = rule.IncrementalPrice;
          priceRule.fromMinutes           = rule.FromMinutes;
          priceRule.toMinutes             = rule.ToMinutes;
          priceRule.exceededMinutes       = rule.ExceededMinutes;
          priceRule.useDateRange          = rule.UseDateRange;
          priceRule.useTimeRange          = rule.UseTimeRange; 
          priceRule.durationCriteria      = rule.DurationCriteria;
          priceRule.bankHolidayRule       = Boolean(rule.BankHolidayRule);
          priceRule.actionRule            = false;

          this.pricingTierRules.push(priceRule);          
        }

        for (var x = 0; x < response.ActionRules.length; x++)
        {
          var rule = response.ActionRules[x];

          var newRule                   = new PriceRuleModel();
          newRule.ruleName              = rule.RuleName;
          newRule.uid                   = Number(rule.PricingTierRuleUid);
          newRule.startDateTime         = rule.StartDateTime;
          newRule.endDateTime           = rule.EndDateTime;
          newRule.actionCheckoutMessage = rule.ActionCheckoutMessage;
          newRule.actionSuccessMessage  = rule.ActionSuccessMessage;
          newRule.mon                   = Boolean(rule.Monday);
          newRule.tue                   = Boolean(rule.Tuesday);
          newRule.wed                   = Boolean(rule.Wednesday);
          newRule.thur                  = Boolean(rule.Thursday);
          newRule.fri                   = Boolean(rule.Friday);
          newRule.sat                   = Boolean(rule.Saturday);
          newRule.sun                   = Boolean(rule.Sunday);
          newRule.renewAnnualy          = Boolean(rule.RenewAnnually);
          newRule.name                  = rule.Name;
          newRule.price                 = rule.Price;
          newRule.useDateRange          = rule.UseDateRange;
          newRule.useTimeRange          = rule.UseTimeRange; 
          newRule.bankHolidayRule       = Boolean(rule.BankHolidayRule);
          newRule.actionRule            = true;

          this.actionRules.push(newRule);          
        }

        if (response.ActivePermitName)
        {
          this.checkoutRequest.activePermitName = response.ActivePermitName;
        }

        if (response.FoundAnprRule && response.Event)
        { 
          this.checkoutRequest.anprEntranceFound      = true;
          this.checkoutRequest.anprEntranceTimestamp  = response.Event.EntranceTime;
          this.checkoutRequest.anprEntranceEventUid   = response.Event.EventUid;
          this.checkoutRequest.anprEntranceDateTime   = response.Event.EntranceDateTime;
          this.checkoutRequest.permitHasExpired       = response.FoundAnprRule.IsExpired;
          this.checkoutRequest.startDateTime          = response.FoundAnprRule.StartDateTime;
          this.checkoutRequest.expiryDateTime         = response.FoundAnprRule.ExpiryDateTime;
          this.checkoutRequest.checkoutAmount         = response.FoundAnprRule.Amount;
          this.checkoutRequest.readableCheckout       = response.FoundAnprRule.CheckoutButton;
          this.checkoutRequest.readableCheckout       = response.FoundAnprRule.ReadableResult
          this.checkoutRequest.base64Overview         = atob(response.Event.Base64Overview);
        }

        this.checkoutRequest.noReturnMessage        = response.NoReturnMessage;
      }
      else
      {
        this.checkoutRequest.success = false;
        this.checkoutRequest.errorMessage = response.Message;
      }

      return this.checkoutRequest;
    }),
    catchError((err) => { 
      
      this.checkoutRequest = null;
      return throwError(() => err);
  
    }));
  }


  public getChargeForMinutes(minutes, ruleUid, prebookDateTime, rewardUid, unlockedRewardUid): any
  {
    var data = {
      Minutes: minutes,
      RuleUid: ruleUid,
      MaxStayCriteria: this.permitSetupGetMaxStayCriteria(),
      MaxStayMinutes: this.permitSetupGetMaxStayMinutes(),
      MaxStayTime: this.permitSetupGetMaxStayTime(),
      NoReturnWithinMin: this.permitSetupGetNoReturnWithinMinutes(),
      PrebookDateTime: prebookDateTime,
      ClientUid: this.clientService.getClientUid(),
      SubClientUid: this.clientService.getSubClientUid(),
      RewardUid: rewardUid,
      UnlockedRewardUid: unlockedRewardUid
    }

    this.checkoutRequest                       = new CheckoutRequestModel();
    this.checkoutRequest.checkoutTypeUid      = this.isCheckoutForAnActionRule() ? Constants.PAYMENT_TYPE_ACTION_RULE : Constants.PAYMENT_TYPE_PARKING;
    this.checkoutRequest.clientUid            = this.clientService.getClientUid();
    this.checkoutRequest.subClientUid         = this.clientService.getSubClientUid();
    this.checkoutRequest.checkoutReg          = this.getEnterVehicleReg();
    this.checkoutRequest.locationUid          = this.permitSetupGetSelectedLocationUid();
    this.checkoutRequest.locationGroupUid     = this.permitSetupGetSelectedLocationGroupUid();
    this.checkoutRequest.stripeConnectAccount = this.clientService.getClientStripeAccountId();
    this.checkoutRequest.uniqueRef            = this.createUniqueRef();
    this.checkoutRequest.permitId            = this.clientService.getClientCode() + "-" + this.getEnterVehicleReg() + "-" + new Date().getTime()
    
    return this.apiService.getChargeForMinutes(data).pipe(map((response: any) => {

      if (response.Success)
      {
        this.checkoutRequest.readableCheckout           = response.Found.ReadableResult;
        this.checkoutRequest.permitHasExpired           = response.Found.IsExpired;
        this.checkoutRequest.checkoutAmount             = response.Found.Amount;
        this.checkoutRequest.expiryDateTime             = response.Found.ExpiryDateTime;
        this.checkoutRequest.startDateTime              = response.Found.StartDateTime;
        this.checkoutRequest.readableCheckout           = response.Found.CheckoutButton;
        this.checkoutRequest.discountAmount             = response.Found.DiscountAmount;
        this.checkoutRequest.driverFeeText              = response.Found.DriverFeeInfo;
        this.checkoutRequest.pricingRuleUid             = ruleUid;
        this.checkoutRequest.manualStartDateTime        = prebookDateTime;
        this.checkoutRequest.rewardUid                  = response.Found.RewardUid;
        this.checkoutRequest.unlockedRewardUid          = response.Found.UnlockedRewardUid;
      }

      return response;
    }),
    catchError((err) => { 
      
      return throwError(() => err);
  
    }));
  }


  public doubleCheckTheyDontAlreadyHaveParkingPermit(prebookDateTime): any
  {
    var data = {
        LocationUid: this.permitSetupGetSelectedLocationUid(),
        LocationGroupUid: this.permitSetupGetSelectedLocationGroupUid(),
        Reg: this.getEnterVehicleReg(),
        DateTime: prebookDateTime,
        ClientUid: this.clientService.getClientUid(),
        SubClientUid: this.clientService.getSubClientUid()
    };

    return this.apiService.doubleCheckTheyDontAlreadyHaveParkingPermit(data).pipe(map((response: any) => {

      return response;

    }),
    catchError((err) => { 
      
      return throwError(() => err);
  
    }));
  }


  private createUniqueRef()
  {
    var timestampNow = new Date().getTime();

    switch(this.checkoutRequest.checkoutTypeUid)
    {
      case Constants.PAYMENT_TYPE_ACTION_RULE:
        return this.clientService.getClientCode() + "-" + Constants.PAYMENT_TYPE_ACTION_RULE + "-" + this.checkoutRequest.checkoutReg.toUpperCase() + "-" + timestampNow;

      case Constants.PAYMENT_TYPE_PARKING:
        return this.clientService.getClientCode() + "-" + Constants.PAYMENT_TYPE_PARKING + "-" + this.checkoutRequest.checkoutReg.toUpperCase() + "-" + timestampNow;

      default:
        return null;
    }
  }


  public createCheckoutRequest(checkoutRequest): any 
  {
    this.clearCheckoutResponse();

    this.checkoutRequest = checkoutRequest;

    this.checkoutRequest.uniqueRef                  = this.createUniqueRef();
    this.checkoutRequest.appInfo                    = this.getAppInfo();
    this.checkoutRequest.driverName                 = this.getDriverName();
    this.checkoutRequest.deviceUid                  = this.deviceService.getDeviceUid();
    this.checkoutRequest.deviceUuid                 = this.deviceService.getDeviceUuid();
    this.checkoutRequest.paymentSourceCheckoutUid   = Constants.PAYMENT_SOURCE_CHECKOUT_KIOSK
    this.checkoutRequest.paymentSourcePlatformUid   = this.appearanceService.getPaymentPlatform();
    this.checkoutRequest.userUid                    = null;
    this.checkoutRequest.platformCustomerId         = null;
    this.checkoutRequest.isPaymentPlan              = false;
    
    return this.apiService.createCheckoutRequest(checkoutRequest).pipe(map((response: any) => {

      if (response.Success)
      {
        this.checkoutRequest.paymentIntentId            = response.paymentIntentId;
        this.checkoutRequest.paymentIntentClientSecret  = response.clientSecret;
        this.checkoutRequest.pendingTransactionUid      = Number(response.pendingTransactionUid);
        this.checkoutRequest.pendingSubscriptionUid     = response.pendingSubscriptionUid ? Number(response.pendingSubscriptionUid) : null;
        this.checkoutRequest.subscriptionId             = response.subscriptionId;
        this.checkoutRequest.nextPaymentDateTime        = response.planSecondPaymentDateTime;
        this.checkoutRequest.uniqueRef                  = response.uniqueRef;

        if (response.planExpiryDateTime)
        {
          this.checkoutRequest.expiryDateTime = response.planExpiryDateTime;
        }
      }
      else
      {
        if (response.DeviceUid)
        {
          this.deviceService.updateDeviceUid(response.DeviceUid);
        }
      }

      return response;

    }),
    catchError((err) => { 
      
      return throwError(() => err);
  
    }));
  }


  public handleFreeCheckout(checkoutRequest): any
  {
    this.clearCheckoutResponse();

    this.checkoutRequest = checkoutRequest;

    this.checkoutRequest.appInfo                    = this.getAppInfo();
    this.checkoutRequest.driverName                 = this.getDriverName();
    this.checkoutRequest.deviceUid                  = this.deviceService.getDeviceUid();
    this.checkoutRequest.deviceUuid                 = this.deviceService.getDeviceUuid();
    this.checkoutRequest.paymentSourceCheckoutUid   = Constants.PAYMENT_SOURCE_CHECKOUT_KIOSK
    this.checkoutRequest.paymentSourcePlatformUid   = this.appearanceService.getPaymentPlatform();

    return this.apiService.freeCheckout(this.checkoutRequest).pipe(map((response: any) => {

      this.driverName = null;
      
      if (response.Success)
      {
        this.createCheckoutResponse(response);
      }
      else
      {
        if (response.DeviceUid)
        {
          this.deviceService.updateDeviceUid(response.DeviceUid);
        }
      }

      return response;
    }),
    catchError((err) => { 
      
      return throwError(() => err);
  
    }));
  }


  public terminalStartCheckout(data): any 
  {
    return this.apiService.terminalStartCheckout(data).pipe(map((response: any) => {

      return response;
    }),
    catchError((err) => { 
      
      return throwError(() => err);
  
    }));
  }


  public terminalCompleteCheckout(data): any 
  {
    return this.apiService.terminalCompleteCheckout(data).pipe(map((response: any) => {

      return response;
    }),
    catchError((err) => { 
      
      return throwError(() => err);
  
    }));
  }


  public checkoutSuccess(): any 
  {
    this.checkoutResponse = new CheckoutResponseModel();
      
    return this.apiService.checkoutSuccess(this.getCheckoutRequest()).pipe(map((response: any) => {

      if (response.Success)
      {
        this.createCheckoutResponse(response);
      }

      return response;

    }),
    catchError((err) => { 
      
      return throwError(() => err);
  
    }));
  }


  public checkPaymentIntentStatusIssue()
  {
    return this.apiService.checkPaymentIntentStatusIssue(this.getCheckoutRequest()).pipe(map((response: any) => {

      if (response.Success)
      {
        this.createCheckoutResponse(response);
      }

      return response;

    }),
    catchError((err) => { 
      
      return throwError(() => err);
  
    }));
  }


  public makePurchaseOnTerminal(): any
  {
    var data = {
      //Charge: this.getFoundExpiryDate()
    }

    return this.apiService.makePurchaseOnTerminal(data).pipe(map((response: any) => {

      if (response.Success)
      {
        // Create Permit and Add Reference to our Database.
        // this.createCheckoutResponse(response);
      }

      return response;
    }),
    catchError((err) => { 
      
      return throwError(() => err);
  
    }));
  }


  private createCheckoutResponse(response)
  {
    this.checkoutResponse = new CheckoutResponseModel();

    this.checkoutResponse.success                     = true;
    this.checkoutResponse.permitUid                   = response.permitUid;
    this.checkoutResponse.permitId                    = response.permitId;
    this.checkoutResponse.checkoutSite                = response.checkoutSite;
    this.checkoutResponse.checkoutReg                 = response.checkoutReg;
    this.checkoutResponse.checkoutAmount              = response.checkoutAmount;
    this.checkoutResponse.checkoutAuthority           = response.checkoutAuthority;
    this.checkoutResponse.transactionRef              = response.transactionRef;
    this.checkoutResponse.permitStartDateTime         = response.permitStartDateTime;
    this.checkoutResponse.permitStartDateTimeReadable = response.permitStartDateTimeReadable;
    this.checkoutResponse.permitEndDateTime           = response.permitEndDateTime;
    this.checkoutResponse.permitEndDateTimeReadable   = response.permitEndDateTimeReadable;
    this.checkoutResponse.actionSuccessMessage        = this.getCheckoutRequestActionSuccessMsg();
    this.checkoutResponse.transactionUid              = this.getCheckoutRequestTransactionUid();
  }

  
  public getUnlockedReward(qrString): any
  {
    var payload = 
    {
      QrCodeString: qrString,
      LocationUid: this.permitSetupGetSelectedLocationUid(),
      LocationGroupUid: this.permitSetupGetSelectedLocationGroupUid()
    }

    return this.apiService.getUnlockedReward(payload).pipe(map((response: any) => {

      return response;
    }),
    catchError((err) => { 
      
      return throwError(() => err);
  
    }));
  }



  // Getters, Setters -------------------------------------------------------------------------

  public saveReg(reg)
  {
    this.vehicleReg = reg;
  }

  public getEnterVehicleReg(): string
  {
    if (this.vehicleReg)
    {
      return this.vehicleReg;
    }

    return null;
  }

  public getDriverName(): string
  {
    if (this.driverName)
    {
      return this.driverName;
    }

    return null;
  }

  public getAppInfo(): string
  {
    if (this.permitSetup)
    {
      if (this.permitSetup.appInfo)
      {
        return this.permitSetup.appInfo;
      }

      if (this.appInfo)
      {
        return this.appInfo;
      }
    }

    return null;
  }

  public getNoReturnString(): string
  {
    if (this.checkoutRequest)
    {
      if (this.checkoutRequest.noReturnMessage)
      {
        return this.checkoutRequest.noReturnMessage;
      }
    }

    if (this.permitSetup)
    {
      if (this.permitSetup.noReturnWithinMinutes)
      {
        return this.permitSetup.noReturnWithinMinutes + " minutes No Return Policy."
      }
    }

    return "Unrestricted No Return Policy.";
  }

  

  // Payment ------------------------------------------------------------------------

  public setActionRuleCheckout()
  {
    this.checkoutRequest.checkoutRoute          = "standard_action_rule";
    this.checkoutRequest.checkoutTypeUid        = Constants.PAYMENT_TYPE_ACTION_RULE;
    this.checkoutRequest.checkoutReg            = this.getEnterVehicleReg();
    this.checkoutRequest.clientUid              = this.clientService.getClientUid();
    this.checkoutRequest.subClientUid           = this.clientService.getSubClientUid();
    this.checkoutRequest.locationGroupUid       = this.permitSetupGetSelectedLocationGroupUid();
    this.checkoutRequest.locationUid            = this.permitSetupGetSelectedLocationUid();
    this.checkoutRequest.stripeConnectAccount   = this.clientService.getClientStripeAccountId();
    this.checkoutRequest.readableCheckout       = this.selectedRule.actionCheckoutMessage;
    this.checkoutRequest.checkoutAmount         = this.selectedRule.price;
    this.checkoutRequest.driverFeeText          = null;
    this.checkoutRequest.pricingRuleUid         = this.selectedRule.uid;
    this.checkoutRequest.actionSuccessMessage   = this.selectedRule.actionSuccessMessage;
  }


  // Pricing Rules ------------------------------------------------------------------------

  public saveSelectedRule(rule)
  {
    this.selectedRule = rule;
  }

  public getPricingRules(): Array<PriceRuleModel>
  {
    if (this.pricingTierRules)
    {
      return this.pricingTierRules;
    }

    return [];
  }

  public getActionRules(): Array<PriceRuleModel>
  {
    if (this.actionRules)
    {
      return this.actionRules;
    }

    return [];
  }

  public doActionRulesAndParkingRulesExist()
  {
    if (!this.actionRules)
    {
      return false;
    }

    if (!this.pricingTierRules)
    {
      return false;
    }

    if (this.actionRules.length > 0 && this.pricingTierRules.length > 0)
    {
      return true;
    }
    
    return false;
  }

  public getSelectedPricingRule(): PriceRuleModel
  {
    if (this.selectedRule)
    {
      return this.selectedRule;
    }

    return null;
  }

  public getSelectedPricingRuleUid(): number
  {
    if (this.selectedRule)
    {
      return this.selectedRule.uid;
    }
    
    return null;
  }

  public getPricingRuleMinStartString(rule)
  {
    var string = "Permit will start ";

    if(rule.preBookMinStartDate)
    {
      string += " on " + moment(rule.preBookMinStartDate, 'YYYY-MM-DD').format("DD-MM-YYYY");
    }
    
    if(rule.preBookMinStartTime)
    {
      string += " at " + moment(rule.preBookMinStartTime, 'HH:mm:ss').format("HH:mm");
    }
    
    return string;
  }

  public checkIfRuleMinStartPassed(rule)
  {
      var minStart = null;
      var now = moment();

      if(!rule.preBookMinStartDate && !rule.preBookMinStartTime)
      {
        return true;
      }

      if(rule.preBookMinStartDate && rule.preBookMinStartTime)
      {
        minStart = moment(rule.preBookMinStartDate + " " + rule.preBookMinStartTime, 'YYYY-MM-DD HH:mm:ss');
        now = moment(now,'YYYY-MM-DD HH:mm:ss');
      }
      
      if(!rule.preBookMinStartDate && rule.preBookMinStartTime)
      {
        minStart = moment(rule.preBookMinStartTime, 'HH:mm:ss');
        now = moment(now,'HH:mm:ss');
      }
      
      if(rule.preBookMinStartDate && !rule.preBookMinStartTime)
      {
        minStart = moment(rule.preBookMinStartDate, 'YYYY-MM-DD');
        now = moment(now,'YYYY-MM-DD');
      }

      if(minStart.isBefore(now))
      {
        return true;
      }
      else
      {
        return false;
      }    
  }



  // Permit Setup -------------------------------------------------------------------------

  public savePermitSetup(permitSetup)
  {
    this.permitSetup = permitSetup;
    this.localStorageService.savePermitSetup(this.permitSetup);
  }

  public doesPermitSetupExist(): boolean
  {
    if (this.permitSetup)
    {
      if (this.permitSetup)
      {
        return true;
      }
    }

    return false;
  }

  public getPermitSetup(): PermitSetupModel
  {
    if (this.permitSetup)
    {
      return this.permitSetup;
    }

    return null;
  }

  public permitSetupGetSiteName(): string
  {
    if (this.permitSetup)
    {
      if (this.permitSetup.siteName)
      {
        return this.permitSetup.siteName;
      }
    }

    return null;
  }

  public permitSetupGetAssignedSiteUid(): string
  {
    if (this.permitSetup)
    {
      if (this.permitSetup.selectedLocationUid)
      {
        return this.permitSetup.selectedLocationUid;
      }
      else if (this.permitSetup.selectedLocationGroupUid)
      {
        return this.permitSetup.selectedLocationGroupUid;
      }
    }

    return null;
  }

  public permitSetupGetSelectedLocationUid(): any
  {
    if (this.permitSetup)
    {
      if (this.permitSetup.assignToLocation)
      {
        return this.permitSetup.selectedLocationUid;
      }
    }

    return null;
  }

  public permitSetupGetSelectedLocationGroupUid(): any
  {
    if (this.permitSetup)
    {
      if (this.permitSetup.assignToLocationGroup)
      {
        return this.permitSetup.selectedLocationGroupUid;
      }
    }

    return null;
  }

  public permitSetupShouldWeAskForAppInfo(): boolean
  {
    if (this.permitSetup)
    {
      return this.permitSetup.requestAppInfo;
    }

    return false;
  }

  public permitSetupShouldWeAskForDriverName(): boolean
  {
    if (this.permitSetup)
    {
      return this.permitSetup.requestDriverName;
    }

    return false;
  }

  public permitSetupGetMaxStayTime(): string
  {
    if (this.permitSetup)
    {
      return this.permitSetup.maxStayTime;
    }

    return null;
  }

  public permitSetupGetMaxStayMinutes(): number
  {
    if (this.permitSetup)
    {
      return this.permitSetup.maxStayMinutes;
    }

    return null;
  }

  public permitSetupGetNoReturnWithinMinutes(): number
  {
    if (this.permitSetup)
    {
      return this.permitSetup.noReturnWithinMinutes;
    }

    return null;
  }

  public permitSetupGetPrebookMaxDate(): any
  {
    if(this.permitSetup)
    {
      if (this.permitSetup.preBookLimitNumber && this.permitSetup.preBookLimitType)
      {
        var duration: any = this.permitSetup.preBookLimitNumber;
        var criteria: any = this.permitSetup.preBookLimitType;
        return moment().add(duration, criteria).toISOString(true);
      }
    }

    return null;
  }

  public permitSetupIsPrebookingEnabled(): boolean
  {
    if (this.permitSetup)
    {
      return this.permitSetup.prebookingEnabled;
    }

    return false;
  }

  public permitSetupGetMaxStayCriteria(): number
  {
    if (this.permitSetup)
    {
      if (this.permitSetup.maxStayCriteria)
      {
        return this.permitSetup.maxStayCriteria;
      }
    }

    return Constants.MAX_STAY_UNLIMITED;
  }

  public permitSetupGetMaxStayCriteriaName(): string
  {    
    if (this.permitSetup)
    {
      switch(this.permitSetup.maxStayCriteria)
      {
        case Constants.MAX_STAY_CUSTOM:
          return "Max Stay is " + this.permitSetupGetMaxStayMinutes() + " minutes";

        case Constants.MAX_STAY_END_OF_DAY:
          return "Max Stay is the end of the day";

        case Constants.MAX_STAY_SET_TIME:
          return "Max stay is " + this.permitSetupGetMaxStayTime();
      }
    }

    return "Unrestricted Max Stay.";
  }



  // Checkout Request ----------------------------------------------------------------------

  public doesCheckoutRequestExist(): boolean
  {
    if (this.checkoutRequest)
    {
      return true;
    }

    return false;
  }

  public isCheckoutFree(): boolean
  {
    if (this.checkoutRequest)
    {
      if (this.checkoutRequest.checkoutAmount == "0.00")
      {
        return true;
      }
    }

    return false;
  }

  public getCheckoutRequest(): CheckoutRequestModel
  {
    if (this.checkoutRequest)
    {
      return this.checkoutRequest;
    }

    return null;
  }

  public updateCheckoutRequest(checkoutReq)
  {
    this.checkoutRequest = checkoutReq;
  }

  public clearCheckoutRequest()
  {
    this.checkoutRequest = null;
    this.selectedRule = null;
  }

  public getCheckoutRequestActivePermitName(): string
  {
    if (this.checkoutRequest)
    {
      return this.checkoutRequest.activePermitName;
    }

    return null;
  }

  public getCheckoutRequestActionSuccessMsg(): string
  {
    if (this.checkoutRequest)
    {
      if (this.checkoutRequest.actionSuccessMessage)
      {
        return this.checkoutRequest.actionSuccessMessage;
      }
    }

    return "";
  }

  public getCheckoutRequestClientSecret(): string
  {
    if (this.checkoutRequest)
    {
      if (this.checkoutRequest.paymentIntentClientSecret)
      {
        return this.checkoutRequest.paymentIntentClientSecret;
      }
    }

    return null;
  }

  public getCheckoutRequestTransactionUid(): number
  {
    if (this.checkoutRequest)
    {
      if (this.checkoutRequest.pendingTransactionUid)
      {
        return this.checkoutRequest.pendingTransactionUid;
      }
    }

    return null;
  }

  public getCheckoutRequestVehicleReg(): string
  {
    if (this.checkoutRequest)
    {
      if (this.checkoutRequest.checkoutReg)
      {
        return this.checkoutRequest.checkoutReg;
      }
    }

    return null;
  }

  public getCheckoutRequestAnprImage(): string
  {
    if (this.checkoutRequest)
    {
      return this.checkoutRequest.base64Overview;
    }

    return null;
  }

  public isCheckoutRequestForAnAnprEvent(): boolean
  {
    if (this.checkoutRequest)
    {
      return this.checkoutRequest.anprEntranceFound;
    }

    return null;
  }

  public hasPermitExpired(): boolean
  {
    if (this.checkoutRequest)
    {
     if (this.checkoutRequest.permitHasExpired)
     {
       return this.checkoutRequest.permitHasExpired;
     }
    }

    return false;
  }

  public wasCheckoutRequestDiscountApplied(): boolean
  {
    if (this.checkoutRequest)
    {
     if (this.checkoutRequest.discountAmount)
     {
      if (this.checkoutRequest.discountAmount != "0.00")
      {
       return true;
      }
     }
    }

    return false;
  }

  public getCheckoutRequestDiscountAmount(): string
  {
    if (this.checkoutRequest)
    {
     if (this.checkoutRequest.discountAmount)
     {
       return this.checkoutRequest.discountAmount;
     }
    }

    return null;
  }

  public isReadableCheckoutAvailable(): boolean
  {
    if (this.getCheckoutRequestReadableCheckout())
    {
      return true;
    }

    return false;
  }

  public getCheckoutRequestReadableCheckout()
  {
    if (this.checkoutRequest)
    {
      return this.checkoutRequest.readableCheckout;
    }

    return null;
  }

  public getCheckoutRequestAnprEntranceDateTime(): string
  {
    if (this.checkoutRequest)
    {
      return this.checkoutRequest.anprEntranceDateTime;
    }

    return null;
  }

  public getCheckoutRequestDriverFeeInfo(): string
  {
    if (this.checkoutRequest)
    {
      if (this.checkoutRequest.driverFeeText)
      {
        return this.checkoutRequest.driverFeeText;
      }
     
    }
    
    return null;
  }

  public getCheckoutRequestExpiryDate(): string
  {
    if (this.checkoutRequest)
    {
      if (this.checkoutRequest.expiryDateTime)
      {
        return this.checkoutRequest.expiryDateTime;
      }
    }

    return null;
  }

  public getCheckoutRequestAmount(): string
  {
    if (this.checkoutRequest)
    {
      if (this.checkoutRequest.checkoutAmount)
      {
        return this.checkoutRequest.checkoutAmount;
      }
    }

    return "0.00";
  }



  // Checkout Response ---------------------------------------------------------

  public doesCheckoutResponseExist(): boolean
  {
    if (this.checkoutResponse)
    {
      return true;
    }

    return false;
  }

  public clearCheckoutResponse()
  {
    this.checkoutResponse = null;
    this.selectedRule = null;
  }

  public wasCheckoutSuccessful(): boolean
  {
    if (this.checkoutResponse)
    {
      if (this.checkoutResponse.success)
      {
        return true;
      }
    }

    return false;
  }

  public isCheckoutForAnActionRule(): boolean
  {
    if (this.checkoutRequest)
    {
      if (this.checkoutRequest.checkoutRoute)
      {
        if (this.checkoutRequest.checkoutRoute == "standard_action_rule" || this.checkoutRequest.checkoutRoute == "free_action_rule")
        {
          return true;
        }
      }
    }

    return false;
  }

  public didCheckoutCreatePermit(): boolean
  {
    if (this.checkoutResponse)
    {
      if (this.checkoutResponse.permitUid)
      {
        return true;
      }
    }

    return false;
  }

  public getCheckoutResponseUniqueRef(): string
  {
    if (this.checkoutResponse)
    {
      if (this.checkoutResponse.transactionRef)
      {
        if (this.checkoutResponse.transactionRef != " ")
        {
          return this.checkoutResponse.transactionRef;
        }
      }
    }

    return null;
  }

  public getCheckoutResponseVehicleReg(): string
  {
    if (this.checkoutResponse)
    {
      if (this.checkoutResponse.checkoutReg)
      {
        return this.checkoutResponse.checkoutReg;
      }
    }

    return null;
  }

  public getCheckoutResponsePermitStartDateReadable(): string
  {
    if (this.checkoutResponse)
    {
      if (this.checkoutResponse.permitStartDateTimeReadable)
      {
        return this.checkoutResponse.permitStartDateTimeReadable;
      }
    }

    return null;
  }

  public getCheckoutResponsePermitExpiryDateTimeReadable(): string
  {
    if (this.checkoutResponse)
    {
      return this.checkoutResponse.permitEndDateTimeReadable;
    }

    return null;
  }

  public getCheckoutResponseSiteInfo(): string
  {
    if (this.checkoutResponse)
    {
      if (this.checkoutResponse.checkoutSite)
      {
        return this.checkoutResponse.checkoutSite;
      }
    }

    return null;
  }

  public getCheckoutResponseAmount(): string
  {
    if (this.checkoutResponse)
    {
      if (this.checkoutResponse.checkoutAmount)
      {
        return this.checkoutResponse.checkoutAmount;
      }
    }

    return "0.00";
  }

  public getCheckoutResponseActionSuccessMsg(): string
  {
    if (this.checkoutResponse)
    {
      if (this.checkoutResponse.actionSuccessMessage)
      {
        return this.checkoutResponse.actionSuccessMessage;
      }
    }

    return null;
  }
}