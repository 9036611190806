export class TerminalStripeModel {

  device_sw_version: string;
  device_type: string;
  id: string;
  ip_address: string;
  label: string;
  livemode: boolean;
  location: string;
  metadata: any;
  object: string;
  serial_number: string;
  status: string;

  constructor() 
  {

  }
}