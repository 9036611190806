import { PriceRuleModel } from "./price-rule.model";

export class LocationModel {

  name: string;
  presetUid: number;
  locationUid: string;

  noReturnWithinMinutes: number;
  maxStayCriteria: number;
  maxStayMinutes: number;
  maxStayTime: string;
  preBookingEnabled: boolean;
  preBookLimitNumber: number;
  preBookLimitType: string;

  minStartTime: string;
  minStartDate: string;

  pricingTierUid: number;
  pricingTierName: string;
  pricingTierRules: Array<PriceRuleModel>;

  containsCharge: boolean;

  constructor() 
  {

  }
}
