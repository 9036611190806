import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';

import { ApiService } from '../api/api.service';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { DeviceService } from '../device/device.service';
import { AppearanceService } from '../appearance/appearance.service';

import { ClientModel } from 'src/app/models/client.model';
import { PriceRuleModel } from 'src/app/models/price-rule.model';
import { LocationModel } from 'src/app/models/location.model';
import { LocationGroupModel } from 'src/app/models/location-group.model';

import { Constants } from 'src/app/constants/constants';
import { throwError } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ClientService {

  private client: ClientModel = null;
  private setupPageIsUnlocked = false;
  private apiEndpointMode = null;


  constructor(private apiService: ApiService, private localStorageService: LocalStorageService, 
    private appearanceService: AppearanceService, private deviceService: DeviceService) { 

      this.initService();
  }

  
  initService()
  {
    this.appearanceService.listenForEvent('storage:loaded', (data: any) => {

      this.loadFromLocalData();

    });

    this.appearanceService.listenForEvent('user:loggedout', (data: any) => {

      this.logout();

    });
  }


  public loadFromLocalData() 
  {
    this.apiEndpointMode      = this.localStorageService.getApiEndpointMode();
    this.apiService.baseUrl   = this.getApiEndPoint();
    this.client               = this.localStorageService.getClient();
    this.setupPageIsUnlocked  = this.localStorageService.getCanAccessSetupPage();
    
    if (this.client) 
    {
      this.apiService.id = this.client.sessionId;

      this.appearanceService.triggerEvent('user:loggedin', {
        time: new Date()
      });
    } 
    else 
    {
      this.apiService.id = "";
    }
  }


  public logout()
  {
    var deviceUid = null;

    if (this.client)
    {
      if (this.deviceService.isDeviceLoggedIn())
      {
        deviceUid = this.deviceService.getDeviceUid();
      }
    }
  
    this.client = null;
    this.localStorageService.saveClient(this.client);
    this.setupPageIsUnlocked = false;
    this.localStorageService.saveCanAccessSetupPage(this.setupPageIsUnlocked);

    if (deviceUid)
    {
      return this.apiService.logout(deviceUid).pipe(map((response: any) => {
  
            this.apiService.id = null;
  
          return response;
        }),
        catchError((err) => { 
          
          return throwError(() => err);
      
        }
      ));
    }
  }


  public pingApi()
  {
    var data = {
      DeviceUid: this.deviceService.getDeviceUid(),
      DeviceUuid: this.deviceService.getDeviceUuid(),
      AppVersion: Constants.APP_VERSION,
      ClientUid: this.getClientUid(),
      SubClientUid: this.getSubClientUid(),
      DeviceOs: this.deviceService.getDeviceOs(),
      DeviceModel: this.deviceService.getDeviceModel(),
      DeviceType: this.deviceService.getDeviceType()
    }

    this.apiService.pingApi(data).subscribe({
      next: (response: any) => {

        if (response.Success)
        {
          if (response.DeviceUid)
          {
            this.deviceService.updateDeviceUid(response.DeviceUid);
          }
          
          this.saveLatestAppVersion(response.LatestAppVersion);

          if (!response.IsDeviceVerified)
          {
            this.appearanceService.presentToast("Device is awaiting Verification!", Constants.TOAST_INFO);
          }
        }
        else
        {
          this.appearanceService.presentToast(response.Message, Constants.TOAST_ERROR);
        }

      },
      error: (error) => {

        //this.appearanceService.presentToast("User not found", Constants.TOAST_ERROR);
      }
    });
  }


  public login(email, password): any 
  {
    var data = {
      Email: email,
      Password: password,
      OneSignalId: null,
      DeviceUuid: this.deviceService.getDeviceUuid(),
      DeviceType: this.deviceService.getDeviceType(),
      DeviceModel: this.deviceService.getDeviceModel(),
      DeviceOs: this.deviceService.getDeviceOs(),
      AppVersion: Constants.APP_VERSION
    };

    return this.apiService.login(data).pipe(map((response: any) => {
      
      if (response.Success) 
      {
        if (response.SessionId)
        {
          this.setupPageIsUnlocked = true;
          var performedLogin = true;
          this.saveClientData(response, performedLogin);
          return response;  
        }
      }

      this.client = null;
      return response;

    }),
    catchError((err) => { 
      
      this.client = null;
      return throwError(() => err);
  
    }));
  }


  public syncClient(): any 
  {
    var payload = {
      ClientUid: this.getClientUid(),
      DeviceUid: this.deviceService.getDeviceUid(),
      SubClientUid: this.getSubClientUid()
    };

    return this.apiService.syncClient(payload).pipe(map((response: any) => {
      
      if (response.Success) 
      {
        var performedLogin = false;
        this.saveClientData(response, performedLogin);
      }
      
      return response;
    }),
    catchError((err) => { 
      
      this.client = null;
      return throwError(() => err);
  
    }));
  }


  private saveClientData(response, login)
  {
    if (login)
    {
      this.client               = new ClientModel();
      this.client.sessionId     = response.SessionId;
      this.client.clientCode    = response.ClientCode;
      this.client.clientUid     = Number(response.ClientUid);
      this.client.subClientUid  = response.SubClientUid ? Number(response.SubClientUid) : null;
    }

    this.client.logo                     = response.Logo;
    this.client.locationGroups           = Array<LocationGroupModel>();
    this.client.locations                = Array<LocationModel>();
    this.client.allLocations             = Array<LocationModel>();
    this.client.paymentEnabled           = response.PaymentEnabled;
    this.client.stripeAccountId          = response.StripeAccountId;
    this.client.latestAppVersion         = response.LatestAppVersion;
    this.client.numberOfLocations        = 0;
    this.client.numberOfLocationGroups   = 0;

    if (response.DeviceUid)
    {
      this.deviceService.updateDeviceUid(response.DeviceUid);
    }

    // Map Locations.
    if (response.Locations)
    {
      this.client.numberOfLocations = response.Locations.length;

      for (var i = 0; i < response.Locations.length; i++)
      {
        var location = response.Locations[i];

        var newLocation                     = new LocationModel();
        newLocation.name                    = location.LocationName;
        newLocation.locationUid             = location.LocationUid;

        if (!location.PricingConditions)
        {
          this.client.allLocations.push(newLocation);
          continue;
        }

        newLocation.presetUid               = Number(location.LocationPresetUid);
        newLocation.pricingTierUid          = Number(location.PricingTierUid);
        newLocation.pricingTierName         = location.PricingTierName;        
        newLocation.noReturnWithinMinutes   = location.PricingConditions.NoReturnWithinMin;
        newLocation.maxStayMinutes          = location.PricingConditions.MaxStayMinutes;
        newLocation.maxStayCriteria         = Number(location.PricingConditions.MaxStayCriteria);
        newLocation.maxStayTime             = location.PricingConditions.MaxStayTime;
        newLocation.preBookingEnabled       = Boolean(location.PricingConditions.PreBookingEnabled);
        newLocation.preBookLimitNumber      = Number(location.PricingConditions.PreBookLimitNumber);
        newLocation.preBookLimitType        = location.PricingConditions.PreBookLimitType;
        newLocation.pricingTierRules        = Array<PriceRuleModel>();

        var containsCharge = false;

        if (location.TierdPricing)
        {
          for (var x = 0; x < location.TierdPricing.length; x++)
          {
            var tier = location.TierdPricing[x];

            var priceRule                 = new PriceRuleModel();
            priceRule.ruleName            = tier.RuleName;
            priceRule.uid                 = Number(tier.PricingTierRuleUid);
            priceRule.startDateTime       = tier.StartDateTime;
            priceRule.endDateTime         = tier.EndDateTime;
            priceRule.mon                 = Boolean(tier.Monday);
            priceRule.tue                 = Boolean(tier.Tuesday);
            priceRule.wed                 = Boolean(tier.Wednesday);
            priceRule.thur                = Boolean(tier.Thursday);
            priceRule.fri                 = Boolean(tier.Friday);
            priceRule.sat                 = Boolean(tier.Saturday);
            priceRule.sun                 = Boolean(tier.Sunday);
            priceRule.renewAnnualy        = Boolean(tier.RenewAnnually);
            priceRule.name                = tier.Name;
            priceRule.price               = tier.Price;
            priceRule.tierRule            = tier.LocationPricingTierRule;
            priceRule.incrementalRule     = Boolean(tier.IncrementalCharge);
            priceRule.incrementalMinutes  = tier.IncrementalMinutes;
            priceRule.incrementalPrice    = tier.IncrementalPrice;
            priceRule.fromMinutes         = tier.FromMinutes;
            priceRule.toMinutes           = tier.ToMinutes;
            priceRule.exceededMinutes     = tier.ExceededMinutes;
            priceRule.useDateRange        = tier.UseDateRange;
            priceRule.useTimeRange        = tier.UseTimeRange; 
            priceRule.durationCriteria    = tier.DurationCriteria;
            priceRule.bankHolidayRule     = Boolean(tier.BankHolidayRule);

            if (!containsCharge)
            {
              if ((Number(priceRule.price) * 100) > 0)
              {
                containsCharge = true;
              }
            }

            newLocation.pricingTierRules.push(priceRule);          
          }

          newLocation.containsCharge = containsCharge;

          // Only add Location with Pricing Tiers.
          if (newLocation.pricingTierRules.length > 0)
          {
            this.client.locations.push(newLocation);
          }

          this.client.allLocations.push(newLocation);
        }
      };
    }

    if (response.LocationGroups)
    {
      this.client.numberOfLocationGroups = response.LocationGroups.length;

      // Map Location Groups.
      for (var i = 0; i < response.LocationGroups.length; i++)
      {
        var group = response.LocationGroups[i];

        if (!group.PricingConditions)
        {
          continue;
        }

        var newGroup                    = new LocationGroupModel();
        newGroup.name                   = group.LocationGroupName;
        newGroup.pricingTierUid         = Number(group.PricingTierUid);
        newGroup.locationGroupUid       = group.LocationGroupUid;
        newGroup.pricingTierName        = group.PricingTierName;
        newGroup.noReturnWithinMinutes  = group.PricingConditions.NoReturnWithinMin;
        newGroup.maxStayMinutes         = group.PricingConditions.MaxStayMinutes;
        newGroup.maxStayCriteria        = Number(group.PricingConditions.MaxStayCriteria);
        newGroup.maxStayTime            = group.PricingConditions.MaxStayTime;
        newGroup.preBookingEnabled      = Boolean(group.PricingConditions.PreBookingEnabled);
        newGroup.preBookLimitNumber     = Number(group.PricingConditions.PreBookLimitNumber);
        newGroup.preBookLimitType       = group.PricingConditions.PreBookLimitType;

        newGroup.pricingTierRules = Array<PriceRuleModel>();
    
        var containsCharge = false;

        if (group.TierdPricing)
        {
          for (var y = 0; y < group.TierdPricing.length; y++)
          {
            var tier = group.TierdPricing[y];

            var priceRule                 = new PriceRuleModel();
            priceRule.ruleName            = tier.RuleName;
            priceRule.uid                 = Number(tier.PricingTierRuleUid);
            priceRule.startDateTime       = tier.StartDateTime;
            priceRule.endDateTime         = tier.EndDateTime;
            priceRule.mon                 = Boolean(tier.Monday);
            priceRule.tue                 = Boolean(tier.Tuesday);
            priceRule.wed                 = Boolean(tier.Wednesday);
            priceRule.thur                = Boolean(tier.Thursday);
            priceRule.fri                 = Boolean(tier.Friday);
            priceRule.sat                 = Boolean(tier.Saturday);
            priceRule.sun                 = Boolean(tier.Sunday);
            priceRule.renewAnnualy        = Boolean(tier.RenewAnnually);
            priceRule.name                = tier.Name;
            priceRule.price               = tier.Price;
            priceRule.tierRule            = tier.LocationPricingTierRule;
            priceRule.incrementalRule     = Boolean(tier.IncrementalCharge);
            priceRule.incrementalMinutes  = tier.IncrementalMinutes;
            priceRule.incrementalPrice    = tier.IncrementalPrice;
            priceRule.fromMinutes         = tier.FromMinutes;
            priceRule.toMinutes           = tier.ToMinutes;
            priceRule.exceededMinutes     = tier.ExceededMinutes;
            priceRule.useDateRange        = tier.UseDateRange;
            priceRule.useTimeRange        = tier.UseTimeRange; 
            priceRule.durationCriteria    = tier.DurationCriteria;
            priceRule.bankHolidayRule     = Boolean(tier.BankHolidayRule);
    
            if (!containsCharge)
            {
              if ((Number(priceRule.price) * 100) > 0)
              {
                containsCharge = true;
              }
            }

            newGroup.pricingTierRules.push(priceRule);
          }

          newGroup.containsCharge = containsCharge;

          // Only add Location with Pricing Tiers.
          if (newGroup.pricingTierRules.length > 0)
          {
            this.client.locationGroups.push(newGroup);
          }
        }
      };
    }

    this.localStorageService.saveClient(this.client);
    this.apiService.id = this.client.sessionId;

    if (login)
    {
      this.appearanceService.triggerEvent('user:loggedin', {
        time: new Date()
      });
    }
  }


  public getNavBarText(): string
  {
    if (this.isDevMode())
    {
      return "[DEV]";
    }

    return ""
  }


  public isDevMode(): boolean
  {
    if (this.apiEndpointMode)
    {
        if (this.apiEndpointMode == Constants.API_DEV)
        {
          return true;
        }
    }

    return false;
  }


  public isKioskUpdated(): boolean
  {
    if (this.client)
    {
      if (this.client.latestAppVersion != Constants.APP_VERSION)
      {
        return false;
      }
    }

    return true;
  }


  public setDeviceUid(deviceUid)
  {
    this.deviceService.updateDeviceUid(deviceUid);
  }


  public getApiEndPointMode(): number
  {
    if (this.apiEndpointMode)
    {
        return this.apiEndpointMode;
    }

    return Constants.API_PROD;
  }


  public getApiEndPoint(): string
  {
    if (this.apiEndpointMode)
    {
      if (this.apiEndpointMode == Constants.API_PROD)
      {
        return Constants.PROD_URL;
      }
      else if (this.apiEndpointMode == Constants.API_DEV)
      {
        return Constants.DEV_URL;
      }
    }

    return Constants.PROD_URL;
  }


  public saveApiEndpointMode(mode)
  {
    this.apiEndpointMode = mode;
    this.apiService.baseUrl = this.getApiEndPoint();
    this.localStorageService.saveApiEndpointMode(this.apiEndpointMode);
  }


  public isPaymentMerchantAvailable(): boolean
  {
    if (this.client)
    {
      return this.client.paymentEnabled;
    }

    return false;
  }


  public getClientStripeAccountId(): string
  {
    if (this.client)
    {
      return this.client.stripeAccountId;
    }

    return null;
  }


  public setupPageUnlocked(toggle)
  {
    this.setupPageIsUnlocked = toggle;
    return this.localStorageService.saveCanAccessSetupPage(this.setupPageIsUnlocked);
  }


  public saveLatestAppVersion(version)
  {
    if (this.client)
    {
      this.client.latestAppVersion = version;
      this.localStorageService.saveClient(this.client);
    }
  }


  public accessedSetupPageWithKey(): boolean
  {
    return this.setupPageIsUnlocked;
  }


  public doesClientHaveLocations(): boolean
  {
    if (this.client)
    {
      if (this.client.numberOfLocations > 0)
      {
        return true;
      }
    }

    return false;
  }


  public doesClientHaveLocationsWithPricing(): boolean
  {
    if (this.client)
    {
      if (this.client.locations)
      {
        if (this.client.locations.length > 0)
        {
          return true;
        }
      }
    }

    return false;
  }


  public whatCanWeAssignBy(): string
  {
    if (this.doesClientHaveLocationsWithPricing() && this.doesClientHaveLocationGroupsWithPricing())
    {
      return 'both';
    }
    else if (!this.doesClientHaveLocationsWithPricing() && this.doesClientHaveLocationGroupsWithPricing())
    {
      return 'group';
    }
    else if (this.doesClientHaveLocationsWithPricing() && !this.doesClientHaveLocationGroupsWithPricing())
    {
      return 'location';
    }

    return null;
  }


  public doesClientHaveLocationGroupsWithPricing(): boolean
  {
    if (this.client)
    {
      if (this.client.locationGroups)
      {
        if (this.client.locationGroups.length > 0)
        {
          return true;
        }
      }
    }

    return false;
  }


  public isUserLoggedIn(): boolean 
  {
    if (this.client) 
    {
      if (this.client.sessionId)
      {
        return true;
      }
    }

    return false;
  }


  public getLocationGroups(): Array<LocationGroupModel> 
  {  
    if (this.client)
    {
      if (this.client.locationGroups)
      {
        return this.client.locationGroups;
      }   
    }

    return [];
  }


  public getLocationsWithPricing(): Array<LocationModel>
  {  
    if (this.client)
    {
      if (this.client.locations)
      {
        return this.client.locations;
      }
    }

    return [];
  }


  public getAllLocations(): Array<LocationModel>
  {  
    if (this.client)
    {
      if (this.client.allLocations)
      {
        return this.client.allLocations;
      }
    }

    return [];
  }


  public getAnyLocation(locationUid): LocationModel
  {  
    if (this.client)
    {
      var locations = this.getAllLocations();

      for (let location of locations) {

        if (location.locationUid == locationUid)
        {
          return location;
        }
      }
    }
  
    return null;
  }


  public getLocation(locationUid): LocationModel
  {  
    if (this.client)
    {
      var locations = this.getLocationsWithPricing();

      for (let location of locations) {

        if (location.locationUid == locationUid)
        {
          return location;
        }
      }
    }
  
    return null;
  }


  public getLocationGroup(locationGroupUid): LocationGroupModel
  {  
    if (this.client)
    {
      var groups = this.getLocationGroups();

      for (let group of groups) {

        if (group.locationGroupUid == locationGroupUid)
        {
          return group;
        }
      }
    }
  
    return null;
  }


  public getClientCode(): string
  {
    if (this.client)
    {
      return this.client.clientCode;
    }

    return "";
  }
  

  public getClientUid(): number
  {
    if (this.client)
    {
      return this.client.clientUid;
    }

    return null;
  }


  public getSubClientUid(): number 
  {
    if (this.client)
    {
      return this.client.subClientUid;
    }

    return null;
  }


  public getBase64Logo(): string
  {
    if (this.client)
    {
      return "data:image/jpeg;base64, " + this.client.logo;
    }

    return null;
  }


  public getSessionId(): string
  {
    if (this.client)
    {
      return this.client.sessionId;
    }

    return null;
  }


  public toggleTheme()
  {
    var light = this.appearanceService.isLightThemeEnabled();
    light = !light;
    this.appearanceService.setTheme(light);
    this.localStorageService.setLightThemeEnabled(light);
  }
}
