import { LocationModel } from "./location.model";
import { LocationGroupModel } from "./location-group.model";

export class ClientModel {

  sessionId: string;
  clientUid: number;
  subClientUid: number;
  clientCode: string;
  paymentEnabled: boolean;
  stripeAccountId: string;

  latestAppVersion: string;

  locationGroups: Array<LocationGroupModel>;
  locations: Array<LocationModel>;
  allLocations: Array<LocationModel>;

  selectedLocationGroup: any;
  selectedLocation: any;

  selectedExpiryRange: string;
  fixedExpiry: boolean;
  fixedExpiryRange: number;
  logo: string;

  numberOfLocations: number;
  numberOfLocationGroups: number;

  constructor() 
  {
    
  }
}
