export namespace Constants {

    export const APP_VERSION = "2.4";

    // TESTING
    export const API_DEV = 1;
    export const API_PROD = 2;

    export const CLEAR_STORAGE = false;

    // SETTINGS
    //export const DEV_URL                        = "http://localhost/parksmart_api/cwis/index.php/api";
    export const DEV_URL                            = "https://portal.parksmart.dev/parksmart_api/cwis/index.php/api";
    export const PROD_URL                           = "https://portal.parksmart.app/parksmart_api/cwis/index.php/api";

    export const STRIPE_KEY_DEV                     = 'pk_test_4y1fZIdIXUv1IDCnAt9WZo3R00WQg9tGfM';
    export const STRIPE_KEY_PROD                    = 'pk_live_mknzOdiGNkBPuSooDZuzq3ac00cYqKCype';

    export const TOAST_SUCCESS                      = 1;
    export const TOAST_ERROR                        = 2;
    export const TOAST_INFO                         = 3;

    export const ASSIGN_TO_GROUP                    = 1;
    export const ASSIGN_TO_LOCATION                 = 2;

    export const KEYBOARD_REGISTRATION              = 1;
    export const KEYBOARD_DRIVER_NAME               = 2;
    export const KEYBOARD_APP_INFO                  = 3;

    //source_checkout_uid
    export const PAYMENT_SOURCE_CHECKOUT_KIOSK      = 2;

    //source_platform_uid
    export const PAYMENT_SOURCE_PLATFORM_ANDROID    = 1;
    export const PAYMENT_SOURCE_PLATFORM_IOS        = 2;
    export const PAYMENT_SOURCE_PLATFORM_WEB        = 3;

    export const PAYMENT_TYPE_PARKING               = 2;
    export const PAYMENT_TYPE_ACTION_RULE           = 5;

    export const ALL_DAY                            = 1;
    export const BETWEEN_MINUTES                    = 2;
    export const EXCEEDING_MINUTES                  = 3;
    export const RULE_CRITERIA_UNTIL_TIME           = 4;

    export const MAX_STAY_UNLIMITED                 = 1;
    export const MAX_STAY_END_OF_DAY                = 2;
    export const MAX_STAY_CUSTOM                    = 3;
    export const MAX_STAY_SET_TIME                  = 4;

    export const MODAL_CONFIRM_ANPR                 = 1;
    export const MODAL_PRICE_LIST                   = 2;
    export const MODAL_SLIDERS                      = 4;
    export const MODAL_STRIPE_CHECKOUT              = 5;
    export const MODAL_TERMINAL_CHECKOUT            = 6;
    export const MODAL_CHECKOUT_COMPLETE            = 7;



    // STORAGE KEYS
    export const kStorage = 'psdcstorage';
    export const kClient = 'psc';
    export const kBaseUrl = 'bUrl';
    export const kPermit = 'kPer';
    export const kSetupAccess = 'kSa';
    export const kCurDev = "kCurDev";
    export const kCurDevUuid = "kUuid";
    export const kEnvMode = "kEv";
    export const kTerminalConfig = "kTcn";
    export const kAppTheme = "kTheme";
    export const kPrinterConfig = "kPrc";
}