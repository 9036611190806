import { TerminalStripeModel } from "./terminal-stripe.model";

export class TerminalConfig {

  locationUid: number;
  status: string;
  init: boolean;
  connected: boolean;
  shouldReconnect: boolean;
  stripeReaderObject: TerminalStripeModel;
  deviceType: string;
  canBeCancelled: boolean;
  paymentInProgress: boolean;

  constructor() 
  {

  }
}